import {defineStore} from "pinia";
import {authStore} from "@/views/auth/stores/auth.store"
import appService from "@/views/app.service"
import {i18n} from "@/i18n";
import {showSuccessAlert} from "@/helpers/sweetAlert";

export const appStore = defineStore('appStore', {
    state: () => ({
        error: {
            message: '',
            type: '',
            popupTime: 5000,
            percentage: '0%'
        },
        form: {
            description: '',
            user_id: ''
        },
        navItemsLoaded: false,
        showBugReportModal: false,
        showUpgradeFormModal: false,
        isBugReportLoading: false,
        showSideNavigation: true,
        isMobile: false

    }),
    actions: {
        async submitBugReport() {
            const useAuthStore = authStore();
            this.form.user_id = useAuthStore.user.uuid;

            try {
                await appService.postBug(this.form).then(() => {
                    this.form.description = '';
                    this.showBugReportModal = false;


                    showSuccessAlert('thank you!', 'bug report sent');
                })

            } catch (error) {
                this.setError(`failed to submit bug report`);
            }

            this.isBugReportLoading = false;
        },
        setError(message, type = 'error', totalTime = this.error.popupTime) {
            if (!this.error.message) {
                this.error.message = i18n.t(message).capitalize();
                this.error.type = type;
                const updateInterval = 25;
                let elapsed = 0;
                const interval = setInterval(() => {
                    elapsed += updateInterval;
                    this.error.percentage = `${elapsed / totalTime * 100}%`;
                    if (elapsed >= totalTime) {
                        clearInterval(interval);
                        this.error.message = '';
                        this.error.percentage = '0%';
                    }
                }, updateInterval);
            }
        }
    }

});