import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import i18n from '@/i18n'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createPinia } from 'pinia';
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import '@flaticon/flaticon-uicons/css/all/all.css';
import "vue3-loading-skeleton/dist/style.css";
import { SkeletonLoader } from "vue3-loading-skeleton";

import '@/helpers/stringify';
import config from './mixins/config';

library.add(fas, far, fab)

const pinia = createPinia();

dom.watch();
window.i18t = (key, ...params) => i18n.global.t(key, params);


createApp(App)
    .component( 'SkeletonLoader', SkeletonLoader)
    .use(i18n)
    .use(VueSweetalert2)
    .use(config)
    .use(pinia)
    .use(router)

    .mount('#app')



