<template>
  <div v-if="showConsent" class="cookie-consent fixed bottom-0 w-full bg-FLregistration text-white p-2 text-center">
    <p class="p-2">{{$t('we use cookies to ensure you get the best experience.').capitalize()}}</p>
    <button class="px-4" @click="acceptCookies">{{$t('accept').capitalize()}}</button>
    <button class="px-4" @click="refuseCookies">{{$t('refuse').capitalize()}}</button>
  </div>
  <div v-else-if="cookiesDenied" class="cookie-consent fixed bottom-0 w-full bg-FLregistration text-white p-2 text-center">
    <p>You have refused cookies.</p>
    <button class="px-4" @click="showConsentAgain">Change decision</button>
  </div>

</template>

<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      showConsent: false,
      cookiesDenied: false,

    };
  },
  created() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = this.getCookie('cookies_accepted');
      if (consent === null) {
        this.showConsent = true;
      } else if (consent === 'false') {
        this.cookiesDenied = true;
      }
    },
    acceptCookies() {
      this.setCookie('cookies_accepted', 'true', 365);
      this.showConsent = false;
      this.cookiesDenied = false;
    },
    refuseCookies() {
      this.setCookie('cookies_accepted', 'false', 365);
      this.showConsent = false;
      this.cookiesDenied = true;
      this.eraseCookie('accessToken');
      this.eraseCookie('workspace');
    },
    showConsentAgain() {
      this.showConsent = true;
      this.cookiesDenied = false;
    },
    setCookie(name, value, days) {
      const d = new Date();
      d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + d.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    },
    getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
    eraseCookie(name) {
      document.cookie = name + '=; Max-Age=-99999999;';
    },
  },
};
</script>
