import {getData, postData, deleteData, patchData} from '@/helpers/api.helper';
const baseUrl = process.env.VUE_APP_API_URL;

export default {
    async getClassPrograms(uuid){
        return getData(`${baseUrl}/api/classprograms/` + uuid, {}, {}, true);
    },
    async getAllPrograms(queries = ''){
        return getData(`${baseUrl}/api/classprograms?${queries}`, {}, {}, true);
    },
    async postProgram(data){
        return postData(`${baseUrl}/api/classprograms/create`, data, {}, {}, true)
    },
    async updateProgram(data){
        return patchData(`${baseUrl}/api/classprograms/update`, data, {}, {}, true)
    },
    async deleteProgram(data){
        return deleteData(`${baseUrl}/api/classprograms/delete`, data, {}, {}, true)
    },

}