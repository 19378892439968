<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white w-full md:h-[calc(100vh-140px)]">
    <div class="flex justify-between">
      <div>
        {{ $t('amount of main categories').capitalize() }}: {{ categories.length }}
      </div>
      <div>
        <button-component @click="openCreateModal()" v-if="useAuthStore.permissions.includes('users_create')"
                          buttonText="create category" buttonColor="secondary" prefix-icon="fi fi-br-plus"/>
      </div>
    </div>
    <div class="border-t border-gray-200">
      <dl>
        <category-list :categories="categories" :depth="0"/>
      </dl>
    </div>
    <loading-component v-if="useCategoryStore.categoriesListLoading"/>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store.js";
import {categoryStore} from "@/views/categories/stores/category.store";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import CategoryList from "@/views/categories/components/CategoryList.vue";

export default {
  name: 'OverviewUserComponent',
  components: {CategoryList, LoadingComponent, ButtonComponent},

  setup() {
    const useCategoryStore = categoryStore();
    const useAuthStore = authStore();

    return {
      useCategoryStore,
      useAuthStore
    }
  },

  props: {
    categories: {
      required: true,
      type: Array
    }
  },

  methods: {
    openCreateModal() {
      this.useCategoryStore.showEditCategoryModal = true
      this.useCategoryStore.editMode = false
    },
  }
}
</script>
