<template>
  <router-view>
    <div class="grid-cols-1 grid gap-4 p-6 h-[calc(100vh-100px)] overflow-y-auto">
      <overview-agenda-component :programs="classWeeks"/>
      <edit-agenda-view-component/>

    </div>
  </router-view>


</template>

<script>
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {computed} from "vue";
import OverviewAgendaComponent from "@/views/agenda/components/OverviewAgendaComponent.vue";
import EditAgendaViewComponent from "@/views/agenda/EditAgendaView.vue";


export default {
  name: 'AgendaView',
  components: {EditAgendaViewComponent, OverviewAgendaComponent},

  setup() {

    const useAgendaStore = agendaStore();
    const classWeeks = computed(() => useAgendaStore.programs.map(program => ({
      uuid: program.uuid,
      title: program.name,
      description: program.description,
      books: program.books,
      showImage: false,
      showButton: false,
    })));

    return {
      classWeeks,
      useAgendaStore
    }
  },
  mounted() {
    this.useAgendaStore.programsLoading = true;
    this.fetchAllPrograms();
  },
  methods: {
    async fetchAllPrograms() {
      await this.useAgendaStore.fetchAllPrograms();
      let classWeeks = [];
      let programs = this.useAgendaStore.programs;

      for (let program of programs) {
        let classWeek = {
          title: program.name,
          description: program.description,
          showImage: false,
          showButton: false
        };

        classWeeks.push(classWeek);
      }

      this.classWeeks = classWeeks
    }
  }
}
</script>

