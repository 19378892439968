<template>
  <div class="flex justify-center">
    <div class="text-center">
      <h1 class="text-6xl font-bold text-FLregistration mb-4">404</h1>
      <p class="text-2xl text-gray-700 mb-8">{{$t('oops!').capitalize()}} {{$t('this page doesn\'t exist').capitalize()}}</p>
      <router-link to="/" class="bg-FLregistration text-white px-6 py-3 rounded-lg transition">
        {{$t('back to home').capitalize()}}
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFoundPage',
}
</script>

<style scoped>
</style>
