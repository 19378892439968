import {createI18n} from 'vue-i18n';
import EN from "@/lang/en.json";
import NL from "@/lang/nl.json";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

const instance = createI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    silentFallbackWarn: true,

    messages: {
        en: EN,
        nl: NL
    }
});
export default instance;
export const i18n = instance.global;
