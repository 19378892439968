<template>
  <div v-if="useLibraryStore.showEditBookModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" @click.self="closeModal">

    <div class="relative top-20 mx-auto p-5 border md:w-2/5 w-full shadow-lg rounded-md bg-white h-full md:h-[calc(100vh-200px)] overflow-y-auto">
      <!-- Close Icon -->
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-xl leading-6 font-medium text-gray-900" v-if="!useLibraryStore.editMode">{{$t('create lesson').capitalize()}}</h3>
      <h3 class="text-xl leading-6 font-medium text-gray-900" v-else>{{$t('update lesson').capitalize()}}</h3>
      <hr class="mb-4">
      <form @submit.prevent="submitBook(useLibraryStore.form.uuid)" class="pt-2 grid grid-cols-2 gap-4">
        <input-component input-type="hidden" placeholder="Id" v-model="useLibraryStore.form.uuid"
                         inputColor="primary"
                         class="w-full col-span-2" label="id" required v-if="useLibraryStore.form.uuid"/>
        <select-component class="w-full col-span-2" label="is it a demo lesson?" inputColor="secondary" v-model="useLibraryStore.form.is_demo" :options="isDemoOptions" required/>
        <input-component input-type="text" placeholder="lesson name" v-model="useLibraryStore.form.name"
                         inputColor="primary"
                         class="w-full mb-4 col-span-2" label="Lesson name" required/>
        <h3 class="text-gray-900">{{$t('categories').capitalize()}}</h3>
        <hr class="col-span-2">
        <div v-for="category in useLibraryStore.categories" :key="category">
          {{category.name}}
          <div v-if="category.subcategories.length">
            <checkbox-component v-for="subcategory in category.subcategories"
                                :field-id="subcategory.id"
                                :label="subcategory.name"
                                :key="subcategory"
                                v-model="useLibraryStore.form.category_ids"
                                :checked="useLibraryStore?.form?.category_ids?.includes(subcategory.id)"
                                @update:checked="checkCategory(subcategory.id, $event)"
            ></checkbox-component>
          </div>
        </div>

        <div class="col-span-2 grid grid-cols-2 gap-4">
          <div class="w-full mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="lessonFile">
              {{ useLibraryStore.form.file_name || $t('choose lesson').capitalize()}}
            </label>
            <input id="lessonFile" type="file" accept="application/pdf" @change="handleFileUpload" class="hidden"/>
            <label for="lessonFile" class="cursor-pointer py-2 px-4 bg-FLregistration text-white rounded-md shadow-sm hover:bg-FLregistrationlight">
              {{$t('select file').capitalize()}}
            </label>
          </div>

          <div class="w-full mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="thumbnail">
              {{ useLibraryStore.form.image_name || $t('choose thumbnail').capitalize() }}
            </label>
            <input id="thumbnail" type="file" accept="image/jpeg" @change="handleImageUpload" class="hidden"/>
            <label for="thumbnail" class="cursor-pointer py-2 px-4 bg-FLregistration text-white rounded-md shadow-sm hover:bg-FLregistrationlight">
              {{$t('select file').capitalize()}}
            </label>
          </div>

          <input-component input-type="url" placeholder="Enter YouTube link" v-model="useLibraryStore.form.url"
                           inputColor="primary"
                           class="w-full mb-4 col-span-2" label="Lesson link"/>
        </div>
        <textarea-component placeholder="Description" v-model="useLibraryStore.form.description"
                            inputColor="secondary"
                            class="w-full col-span-2 mb-4" label="Description" required/>

        <button-component buttonText="Submit" buttonColor="secondary" class="col-span-2 sticky bottom-0 !w-full mb-4" prefix-icon="fi fi-rr-paper-plane-top" :is-loading="useLibraryStore.isLoading" />
      </form>
    </div>
  </div>
</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import SelectComponent from "@/components/forms/SelectComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue";
import { libraryStore } from "@/views/library/stores/library.store.js";

export default {
  name: 'CreateBookComponent',
  components: {
    CheckboxComponent,
    TextareaComponent,
    SelectComponent,
    ButtonComponent,
    InputComponent
  },

  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    };
  },

  data() {
    return {
      isDemoOptions: [
        {
          value: 1,
          text: 'yes'
        },
        {
          value: 0,
          text: 'no'
        }
      ]
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    isValidYouTubeUrl(url) {
      if (!url) return true;
      const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
      return pattern.test(url);
    },

    checkCategory(categoryId, checked) {
      let storeSelectedCategories = this.useLibraryStore.form.category_ids;
      if (checked) {
        storeSelectedCategories.push(categoryId);
      } else {
        storeSelectedCategories.splice(storeSelectedCategories.indexOf(categoryId), 1);
      }
    },

    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.useLibraryStore.form.image_name = file.name;
        this.useLibraryStore.form.image = file;
      } else {
        this.useLibraryStore.form.image_name = '';
        this.useLibraryStore.form.image = null;
      }
    },

    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.useLibraryStore.form.file_name = file.name;
        this.useLibraryStore.form.file = file;
      } else {
        this.useLibraryStore.form.file_name = '';
        this.useLibraryStore.form.file = null;
      }
    },

    async getCategories() {
      await this.useLibraryStore.fetchAllCategories();
    },

    async submitBook() {
      if (!this.isValidYouTubeUrl(this.useLibraryStore.form.url)) {
        return;
      }

      if (this.useLibraryStore.editMode) {
        await this.useLibraryStore.updateBook();
      } else {
        await this.useLibraryStore.createBook();
      }

      this.useLibraryStore.lastFetched = null;

      await this.getCategories();
      await this.useLibraryStore.fetchAllBooks();
    },

    closeModal() {
      this.useLibraryStore.showEditBookModal = false;
    }
  }
}
</script>
