<template>
  <div class="rounded-xl relative transition-all flex flex-col drop-shadow-lg">
    <div
        class="absolute z-20 inset-0 rounded-xl flex items-center pb-20 justify-center gap-4 opacity-0 hover:opacity-100 transition-opacity"
        v-if="allowEdits && !isLocked"
    >
      <!-- Show button -->
      <button
          v-if="showDetailButton && useAuthStore.permissions?.includes(showPermission)"
          @click="$emit('show')"
          class="text-white bg-FLregistration hover:scale-125 duration-200 hover:bg-FLregistration h-8 w-8 rounded-full"
      >
        <i class="fa fa-eye"></i>
      </button>

      <!-- Edit button -->
      <button
          v-if="editDetailButton && useAuthStore.permissions?.includes(updatePermission)"
          @click="$emit('edit')"
          class="text-white bg-FLregistration hover:scale-125 duration-200 h-8 w-8 rounded-full"
      >
        <i class="fa fa-pen"></i>
      </button>
      <!-- Delete button -->
      <button
          v-if="deleteDetailButton && useAuthStore.permissions?.includes(deletePermission)"
          @click="$emit('delete', title)"
          class="text-white bg-FLregistration hover:scale-125 duration-200 h-8 w-8 rounded-full"
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
    <div class="flex-grow">
      <div class="relative" v-if="showImage" :class="{ 'cursor-not-allowed': isLocked }">
        <div
            v-if="isLocked"
            class="absolute inset-0 z-30 bg-black bg-opacity-75 flex flex-col items-center py-4 justify-center rounded-t-lg text-center"
        >
          <icon-component icon="fi fi-sr-lock text-white md:text-3xl text-lg"></icon-component>
          <p class="text-white md:text-sm text-xs px-4 pt-1">{{ $t('more lessons?').capitalize() }}</p>
          <button-component
              @click="useAuthStore?.user?.request_upgrade ? useAppStore.showUpgradeFormModal = false : useAppStore.showUpgradeFormModal = true"
              :always-show-text="true" class="hover:scale-105 mt-2 md:text-sm text-xs p-2" button-color="secondary"
              :button-text="useAuthStore?.user?.request_upgrade ? 'requested!' : 'contact us'"
              :disabled="!!useAuthStore?.user?.request_upgrade"/>
        </div>
        <div class="relative">
          <img v-if="image"
               class="rounded-t-lg opacity-0 object-cover w-full transition-opacity duration-1000 ease-in-out max-h-[calc(30vh-9rem)]"
               :class="{'opacity-0': !imageLoaded, 'opacity-100': imageLoaded}"
               :src="image"
               alt=""
               @load="onImageLoad"/>
          <SkeletonLoader
              v-else
              :style="{ height: 'calc(30vh - 9rem)' }"
          />
          <div
              class="absolute bottom-0 rounded-tl rounded-tr w-full text-center text-xs text-white bg-FLregistration py-1"
              v-if="isDemo">
            {{ $t('demo lesson').capitalize() }}
          </div>
        </div>
      </div>
      <div class="p-4 flex flex-col justify-between flex-1" :class="{ 'cursor-not-allowed': isLocked }"
           @click="!isLocked && $emit('show')">
        <div>
          <div v-if="categories" class="overflow-hidden whitespace-nowrap" @mouseover="startScroll"
               @mouseleave="stopScroll">
            <div ref="badgeContainer" class="inline-flex items-center space-x-2">
              <badge-component v-for="category in categories"
                               size="sm"
                               :badge-color="category.background_color"
                               :text-color="category.text_color"
                               :key="category.id"
                               :badge-text="category.name"
                               class="flex-shrink-0"
              />
            </div>
          </div>
          <a :class="{ 'cursor-not-allowed': isLocked }" href="#">
            <div>
              <span v-if="showIconTitle" class="text-">
                <icon-component :icon="iconName"/>&nbsp;
              </span>
              <span v-if="title" :class="'text-' + titleSize">{{ title }}

              </span>
              <SkeletonLoader v-else/>
              <div class="mt-1 max-h-16 overflow-y-auto text-sm">{{ description }}</div>

            </div>
          </a>
        </div>
        <slot></slot>
        <div v-if="showButton" class="mt-4">
          <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 14 10">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import BadgeComponent from "@/components/forms/BadgeComponent.vue";
import {authStore} from "@/views/auth/stores/auth.store.js";
import {appStore} from "@/views/app.store.js"
import IconComponent from "@/components/forms/IconComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  name: 'CardComponent',
  components: {IconComponent, BadgeComponent, ButtonComponent},

  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    return {
      useAuthStore,
      useAppStore
    };
  },
  props: {
    updatePermission: {
      type: String,
      required: false,
    },
    deletePermission: {
      type: String,
      required: false,
    },
    showPermission: {
      type: String,
      required: false,
    },
    showIconTitle: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    updatedAt: {
      type: String,
      required: false,
    },
    showImage: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    isLocked: {
      type: Boolean,
      default: false,
    },
    showButton: {
      type: Boolean,
    },
    titleSize: {
      type: String,
    },
    categories: {
      type: Array,
    },
    allowEdits: {
      type: Boolean,
      default: false,
    },
    showDetailButton: {
      type: Boolean,
      default: true,
    },
    editDetailButton: {
      type: Boolean,
      default: true,
    },
    deleteDetailButton: {
      type: Boolean,
      default: true,
    },
    image: {
      required: false,
    },
    imageHeight: {
      required: false,
      default: 40
    },
    isDemo: {
      type: Boolean,
      default: false,
      required: false,
    },
    views: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      imageLoaded: false,
    }
  },
  computed: {
    formattedUpdatedAt() {
      if (this.updatedAt) {
        const date = new Date(this.updatedAt);
        // Format the date as DD-MM-YYYY
        const formattedDate = date.toLocaleDateString("nl-NL", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
        return `${formattedDate}`;
      }
      return "";
    },
  },
  methods: {
    onImageLoad() {
      this.imageLoaded = true;
    },
    startScroll() {
      const container = this.$refs.badgeContainer;
      let direction = 1;
      let speed = 2;

      this.scrollInterval = setInterval(() => {
        if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
          direction = -1;
        } else if (container.scrollLeft <= 0) {
          direction = 1;
        }
        container.scrollLeft += direction * speed;
      }, 20);
    },
    stopScroll() {
      clearInterval(this.scrollInterval);
    },
    beforeDestroy() {
      clearInterval(this.scrollInterval);
    }
  },

};
</script>
