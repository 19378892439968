<template>
  <button :class="[buttonClasses, {'w-8 h-8': !alwaysShowText, 'w-auto': alwaysShowText}]"
          :disabled="isLoading || disabled"
          class="md:h-10 md:w-auto text-md rounded-lg mb-4 flex items-center justify-center transition-all duration-200 ease-out hover:drop-shadow-lg"
          @click="emitName ? $emit(emitName) : null">
    <span v-if="isLoading" class="flex items-center justify-center">
      <loading-component size="32"/>
    </span>
    <span v-else class="flex items-center justify-center">

      <span v-if="prefixIcon" class="text-lg md:hidden"><icon-component :icon="prefixIcon"></icon-component></span>
      <span :class="alwaysShowText ? '' : 'hidden md:inline'">{{ $t(buttonText.toLowerCase()).capitalize() }}</span>
<!--      <span v-if="suffixIcon" class="text-lg md:hidden"><icon-component :icon="prefixIcon"></icon-component></span>-->

    </span>
  </button>
</template>

<script>
import LoadingComponent from '@/components/LoadingComponent.vue'
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'ButtonComponent',
  components: {IconComponent, LoadingComponent},

  props: {
    emitName: {
      type: String,
      required: false
    },
    buttonText: {
      type: String,
      default: '',
    },
    buttonColor: {
      type: String
    },
    prefixIcon: {
      type: String,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledColor: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    alwaysShowText: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    buttonClasses() {
      let baseClasses = [
        'transition-all',
        'duration-300',
        'ease-linear',
        'h-10',
        'text-md',
        'rounded',
        'mb-4',
        'flex',
        'items-center',
        'justify-center',
        this.selectColors(this.buttonColor).backgroundColor,
        this.selectColors(this.buttonColor).text,
        this.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        this.disabled ? 'opacity-60' : 'opacity-100',
        this.selectColors(this.buttonColor).hoverColor? 'hover:'+ this.selectColors(this.buttonColor).hoverColor : ''
      ];

      if (this.isLoading) {
        baseClasses.push('w-10', 'p-1');
      } else {
        baseClasses.push('px-4');
      }

      return baseClasses;
    }
  },
  methods: {
    selectColors(buttonColor) {
      if (buttonColor === 'primary') {
        if(this.disabled){
          return {
            backgroundColor: 'bg-FLLight',
            text: 'text-white'
          }
        } else {
          return {
            backgroundColor: 'bg-FLPrimary',
            hoverColor: 'bg-FLSecondary',
            text: 'text-white'
          }
        }
      } else if (buttonColor === 'secondary') {
        if(this.disabled){
          return {
            backgroundColor: 'bg-FLregistrationlight',
            text: 'text-white'
          }
        } else {
          return {
            backgroundColor: 'bg-FLregistration',
            hoverColor: 'bg-FLregistrationlight',
            text: 'text-white'
          }
        }
      } else {
        return {
          backgroundColor: 'bg-gray-400',
          text: 'text-white'
        }
      }
    }
  },
}
</script>