<template>
  <div class="bg-white flex flex-col sm:flex-row">
    <!-- Left side with the logo and login form -->
    <div class="md:w-1/2 sm:w-full flex flex-col items-center p-4 sm:p-8">
      <!-- Logo -->
      <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo"
           class="mb-4 sm:mb-8 ml-4 sm:absolute sm:top-0 sm:left-0 sm:mt-4">


      <div class="items-center my-auto" v-if="useAuthStore.emailVerify.status === 'email_not_verified'">
        <div class="text-center">
          <IconComponent icon="text-green-600 text-4xl fi fi-ss-shield-check"/>
        </div>
        <h1 class="text-2xl mb-4 md:mb-8 text-center sm:text-center">{{ $t('confirm email').capitalize() }}
        </h1>
        <div class="items-center">
          <form @submit.prevent="handleEmailConfirm()" class="text-center">
            <notification-popup v-if="notification.message" :message="notification.message" :type="notification.type"
                                @close="notification.message = ''"/>
            <p class="pb-5">
              {{ $t('please enter a password to have access to the system').capitalize() }}.
            </p>
            <input-component v-model="useAuthStore.emailVerify.password" input-type="password" placeholder="password"
                             inputColor="primary" class="w-full" :required="true"/>
            <input-component v-model="useAuthStore.emailVerify.password_confirmation" input-type="password"
                             placeholder="confirm password"
                             inputColor="secondary" class="w-full" :required="true"/>
            <button-component type="submit" class="shadow-xl mx-auto w-full md:w-full " buttonText="confirm and login"
                              :is-loading="useAuthStore.isLoading" buttonColor="secondary" :always-show-text="true"/>
          </form>
        </div>

      </div>
      <div class="items-center my-auto" v-else-if="useAuthStore.emailVerify.status === 'email_verified'">
        <div class="text-center">
          <IconComponent icon="text-green-600 text-4xl fi fi-ss-shield-check"/>
        </div>
        <h1 class="text-2xl mb-4 md:mb-8 text-center sm:text-center">{{ $t('email verified').capitalize() }}
        </h1>
        <div class="items-center">
          <form @submit.prevent="router.push({ path: '/login' })" class="text-center">
            <notification-popup v-if="notification.message" :message="notification.message" :type="notification.type"
                                @close="notification.message = ''"/>
            <p class="pb-5">
              {{ $t('you can login at the homepage').capitalize() }}.
            </p>

            <button-component type="submit" class="shadow-xl mx-auto w-full md:w-full " buttonText="login"
                              :is-loading="useAuthStore.isLoading" buttonColor="secondary" :always-show-text="true"/>
          </form>
        </div>
      </div>
      <div class="items-center my-auto"
           v-else-if="useAuthStore.emailVerify.status === 'email_verified_password_not_set'">
        <div class="text-center">
          <IconComponent icon="text-green-600 text-4xl fi fi-ss-shield-check"/>
        </div>
        <h1 class="text-2xl mb-4 md:mb-8 text-center sm:text-center">{{ $t('email already confirmed').capitalize() }}
        </h1>
        <div class="items-center">
          <form @submit.prevent="handleEmailConfirm()" class="text-center">
            <notification-popup v-if="notification.message" :message="notification.message" :type="notification.type"
                                @close="notification.message = ''"/>
            <p class="pb-5">
              {{ $t('we see that you don\'t have a password set up').capitalize() }}.
              {{ $t('please create a password here').capitalize() }}.
            </p>
            <input-component v-model="useAuthStore.emailVerify.password" input-type="password" placeholder="password"
                             inputColor="primary" class="w-full" :required="true"/>
            <input-component v-model="useAuthStore.emailVerify.password_confirmation" input-type="password"
                             placeholder="confirm password"
                             inputColor="secondary" class="w-full" :required="true"/>
            <button-component type="submit" class="shadow-xl mx-auto w-full md:w-full " buttonText="confirm and login"
                              :is-loading="useAuthStore.isLoading" buttonColor="secondary" :always-show-text="true"/>
          </form>
        </div>
      </div>
    </div>


    <div class="hidden sm:block sm:w-1/2">
      <img src="../../assets/RegistrationImage.png" alt="Girl Holding Book" class="w-full  sm:h-full object-cover">
    </div>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {useRouter} from "vue-router";
import {ref} from "vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'EmailConfirm',
  components: {IconComponent, ButtonComponent, NotificationPopup, InputComponent},

  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    const notification = ref({
      message: '',
      type: 'error',
    });

    return {
      useAuthStore,
      useAppStore,
      router,
      notification,
    };
  },

  mounted() {
    this.useAuthStore.checkEmail();
  },

  methods: {
    handleEmailConfirm() {
      this.useAuthStore.confirmEmail();

    }
  },
}
</script>