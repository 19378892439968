import Agenda from "@/views/agenda/AgendaView.vue";
import ShowAgenda from "@/views/agenda/ShowAgendaView.vue";
import i18n from "@/i18n";

export default [
    {
        path: '/agenda',
        name: 'Agenda',
        component: Agenda,
        meta: {
            title: i18n.global.t('class programs'),
            permission: 'class_weeks_view'


        }
    },
    {
        path: '/agenda/:uuid',
        name: 'Class Programs details',
        component: ShowAgenda,
        meta: {
            title: i18n.global.t('class program'),
            permission: 'class_weeks_view'

        }
    }
];
