<template>
  <div class="flex items-center">
    <input
        v-model="internalChecked"
        :id="fieldId"
        type="checkbox"
        :class="[
        'form-checkbox',
        'w-4', 'h-4',
        'text-blue-600',
        'bg-gray-100',
        'border-gray-300',
        'rounded',
        'focus:ring',
        'dark:bg-gray-700',
        'dark:border-gray-600',
        'transition',
        'duration-200',
        'ease-in-out',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
      ]"
        :disabled="disabled"
    >
    <label
        :for="fieldId"
        class="ml-2 text-sm font-medium cursor-pointer select-none"
    >{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxComponent',
  props: {
    label: String,
    checked: Boolean,
    fieldId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalChecked: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('update:checked', value);
      }
    }
  }
}
</script>
