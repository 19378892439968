<template>
  <!-- add basic form modal positioned at the center of the screen -->
  <div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
    <div class="bg-white md:w-1/2 w-full md:py-16 md:px-24 p-4 rounded-lg relative text-center max-h-full overflow-y-auto">
      <button @click="useAppStore.showUpgradeFormModal = false" class="absolute top-8 right-8 text-2xl text-black">&times;</button>
      <img v-if="useAuthStore?.image"
           :src="useAuthStore?.image"
           alt="workspace logo" height="72" width="72" class="mx-auto mb-2 rounded-full border-solid border-1"/>
      <h2 class="text-2xl font-bold mb-4">{{$t('get access to all (350) lessons!').capitalize()}}</h2>
      <p class="mb-8">{{$t('the lessons will be custom made for your school:').capitalize()}}</p>
      <div class="flex justify-center px-10">
        <!-- Promotion list why you should upgrade -->
        <ul class="w-auto text-left">
          <li class="items-center mb-4 py-2 md:px-4 rounded-lg">
            <div class="flex text-xl items-center">
              <i class="fi fi-rr-phone-flip mr-4 text-[36px] text-FLregistration"></i>
              <span class="text-3xl px-2">1.</span><span class="mt-1">{{$t('fill in your phone number').capitalize()}}</span>
            </div>
          </li>
          <li class="items-center mb-4 py-2 md:px-4 rounded-lg">
            <div class="flex text-xl items-center">
              <i class="fi fi-rr-meeting mr-4 text-[36px] text-FLregistration"></i>
              <span class="text-3xl px-2">2.</span><span class="mt-1">{{$t('we will discuss the situation and wishes of your school (by phone or mail)').capitalize()}}</span>
            </div>
          </li>
          <li class="items-center mb-4 py-2 md:px-4 rounded-lg">
            <div class="flex text-xl items-center">
              <i class="fi fi-rr-check mr-4 text-[36px] text-FLregistration"></i>
              <span class="text-3xl px-2">3.</span><span class="mt-1">{{$t('get custom made lessons').capitalize()}}</span>
            </div>
          </li>
        </ul>
      </div>
      <input-component
          input-type="text"
          placeholder="+3161234567890"
          v-model="useUserStore.upgradeForm.phone_number"
          inputColor="primary"
          label="phone number"
          required
          class="mb-2 md:!w-1/2"
      />
      <ButtonComponent @click="handleUpgradeButton()" :disabled="!useUserStore.upgradeForm.phone_number" class="hover:scale-105 mt-2 text !p-6 mx-auto text-white bg-FLregistration rounded-lg transition transition-duration-400 hover:bg-FLregistrationlight" button-color="primary" :always-show-text="true" :button-text="'request an upgrade'"></ButtonComponent>

    </div>
  </div>
</template>

<script>

import { appStore } from "@/views/app.store";
import { authStore } from "@/views/auth/stores/auth.store";
import { userStore } from "@/views/users/stores/user.store";
import {showCustomAlert} from "@/helpers/sweetAlert";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
export default {
  name: "UpgradeFormModal",
  components: {InputComponent, ButtonComponent},
  setup() {
    const useAuthStore = authStore();
    const useUserStore = userStore();
    const useAppStore = appStore();

    return {
      useAppStore,
      useAuthStore,
      useUserStore
    }
  },
  methods: {
    handleUpgradeButton() {
      showCustomAlert(
          () => this.requestUserUpgrade(),
          'are you sure you want to upgrade',
          'we will send an email to {workspaceName}',
          {},
          { workspaceName: this.useAuthStore?.workspace?.name },
          'upgrade successfully requested!',
          'you will get a response from {workspaceName} very soon',
          {},
          { workspaceName: this.useAuthStore?.workspace?.name }
      );
    },

    async requestUserUpgrade(){

        await this.useUserStore.requestUserUpgrade();


    },
  },
};

</script>
