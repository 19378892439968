import ShowSettings from "@/views/settings/ShowSettingsView.vue";
import i18n from "@/i18n";

export default [
    {
        path: "/settings",
        component:ShowSettings,

        meta: {
            permission: true,
            title: i18n.global.t('settings'),
        }
    }
]