<template>
  <div class="bg-white flex flex-col sm:flex-row relative">
    <!-- Left side with the logo and login form -->
    <div class="md:w-1/2 sm:w-full flex flex-col items-center p-4 sm:p-8 relative">
      <!-- Logo -->
      <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo" class="mb-4 sm:mb-8 ml-4 sm:absolute sm:top-0 sm:left-0 sm:mt-4">

      <form class="items-center my-auto" @submit.prevent="submitForgotPassword">
        <h1 class="text-2xl mb-2 sm:mb-4 text-center sm:text-center">Wachtwoord opnieuw instellen</h1>
        <p class="text-md text-center">Je ontvangt een e-mail voor het resetten van je wachtwoord</p>
        <div class="items-center">
          <div class=" mx-auto">
            <input-component v-model="store.formPasswordReset.email" type="email" placeholder="E-mail" inputColor="primary" class="w-full mx-auto mb-2 sm:mb-4"/>
            <button-component type="submit" buttonText="Verzenden" class="w-full md:w-1/2 mx-auto" buttonColor="secondary" :always-show-text="true"/>
            <p class="text-center text-md sm:text-lg text-gray-500">
              <RouterLink to="/login" class="underline text-black">Annuleren</RouterLink>
            </p>
          </div>
        </div>
      </form>

      <!-- Privacy Policy and Terms of Service Links -->
      <div class="absolute bottom-4 left-4 flex space-x-4">
        <button @click="showPrivacyPolicy = true" class="text-gray-500 underline">Privacybeleid</button>
        <button @click="showTermsOfService = true" class="text-gray-500 underline">Algemene Voorwaarden</button>
      </div>

    </div>

    <div class="hidden sm:block sm:w-1/2">
      <img src="../../assets/Tumbnail-Inlogomgeving.png" alt="Girl Holding Book" class="w-full sm:h-full object-cover">
    </div>

  <!-- Privacy Policy Popup -->
  <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false" />

  <!-- Terms of Service Popup -->
  <terms-of-service v-if="showTermsOfService" @close="showTermsOfService = false" />

  </div>
</template>
<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import { authStore } from "@/views/auth/stores/auth.store";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import TermsOfService from "@/views/auth/TermsOfService.vue";


export default {
  name: 'RegisterComponent',
  components: {TermsOfService, InputComponent, ButtonComponent, PrivacyPolicy },
  data() {
    return {
      email: '',
      showPrivacyPolicy: false,
      showTermsOfService: false
    };
  },
  setup() {
    const store = authStore();
    return {
      store
    }
  },
  methods: {
    async submitForgotPassword() {
      this.store = authStore();
      try {
        await this.store.forgotPassword();
      } catch (error) {
        console.error("Error when sending email");
      }
    }
  }
}
</script>
