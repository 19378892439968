<template>
  <div>
    <div class="flex min-h-screen">
      <transition name="slide">
        <div v-if="useAppStore.showSideNavigation"
             class="text-white w-full flex flex-col bg-FLLight inset-0 relative animate-slide-in md:animate-none">
          <span class="flex flex-col items-center justify-center py-3 bg-FLLight relative">
            <img
                :src="useAuthStore?.image ? useAuthStore?.image : `https://ui-avatars.com/api/?size=35&name=${useAuthStore.workspace?.name}&rounded=true&background=fff&color=51AFE3`"
                alt="User Avatar" height="128" width="128" class="rounded-full drop-shadow-lg mx-auto">
            <icon-component icon="fi fi-rr-cross ml-auto md:hidden"
                            @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
                            class="absolute top-2 right-4"></icon-component>
            <span class="text-center absolute bottom-2 text-[14px] bg-FLregistration rounded-full w-28 drop-shadow-lg">
              {{
                useAuthStore.role && (useAuthStore?.role?.name === 'demo_user' || useAuthStore?.role?.name === 'reseller_admin') ? useAuthStore.translatedRole.capitalize() : useAuthStore.workspace?.name
              }}
            </span>
          </span>

          <nav class="flex-1 flex flex-col">
            <div>
              <div class="pt-2 bg-FLSecondary"></div>
              <div v-for="navItem in navItems" :key="$t(navItem.title.lowercase().capitalize())"
                   class="bg-FLSecondary text-left text-lg">
                <div v-if="useAuthStore.permissions?.includes(navItem.permission) || !navItem.permission"
                     class="pt-2.5">
                  <RouterLink :to="navItem.to"
                              @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
                              class="flex items-center space-x-5 mr-4 rounded-r-lg px-6 p-2 hover:bg-FLPrimary py-2.5"
                              :class="{ 'bg-FLPrimary border-l-white': $route.path === navItem.to }">
                    <i class="pt-1 pl-2" :class="navItem.icon"></i>
                    <span class="text-white">{{ $t(navItem.title.lowercase()).capitalize() }}</span>
                  </RouterLink>
                </div>
              </div>
            </div>
            <div class="flex-grow"></div>

          </nav>

          <div class="rounded-br-3xl text-lg">
            <div class="py-4" v-if="useAuthStore.permissions?.includes('workspaces_update_own')">
              <RouterLink to="/settings"
                          @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
                          class="flex items-center space-x-5 mr-4 rounded-r-lg px-6 p-2 hover:bg-FLPrimary py-2.5"
                          :class="{ 'bg-FLSecondary border-l-white': $route.path === '/settings'}">
                <icon-component class="pt-1 pl-2" icon="fi fi-ss-settings"></icon-component>
                <span class="text-1xl text-white">{{ $t('settings').capitalize() }}</span>
              </RouterLink>
            </div>
            <div class="py-4" @click.prevent="handleLogout">
              <RouterLink to=""
                          @click.prevent="useAppStore.isMobile ? useAppStore.showSideNavigation = !useAppStore.showSideNavigation : ''"
                          class="flex items-center space-x-5 mr-4 rounded-r-lg px-6 p-2 hover:bg-FLPrimary py-2.5">
                <icon-component class="pt-1 pl-2" icon="fi fi-rs-sign-out-alt"></icon-component>
                <span class="text-1xl text-white">{{ $t('logout').capitalize() }}</span>
              </RouterLink>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from '@/views/app.store';
import {useRouter} from "vue-router";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  components: {
    IconComponent
  },
  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    return {
      useAuthStore,
      router,
      useAppStore
    }
  },
  props: {
    permission: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showDropdown: false,
      showNavigation: false,
      navItems: [
        {name: 'Home', to: '/', title: 'Dashboard', icon: 'fi fi-rr-apps'},
        {
          name: 'Class Weeks',
          to: '/agenda',
          title: 'Class Programs',
          icon: 'fi fi-rr-calendar-day',
          permission: 'class_weeks_view'
        },
        {name: 'Lessons', to: '/library', title: 'Library', icon: 'fi fi-rr-book-alt', permission: 'books_view'},
        {name: 'Users', to: '/users', title: 'Users', icon: 'fi fi-rs-users', permission: 'users_view'},
        {
          name: 'Categories',
          to: '/categories',
          title: 'Categories',
          icon: 'fi fi-rr-layers',
          permission: 'categories_view'
        },
        {
          name: 'Analytics',
          to: '/analytics',
          title: 'Analytics',
          icon: 'fi fi-rr-chart-histogram',
          permission: 'analytics_view'
        },
      ],
    }
  },
  computed: {
    pageTitle() {
      return this.$route.meta.title;
    },
  },
  methods: {
    async handleLogout() {
      try {
        await this.useAuthStore.logout();
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },
    promoteMembership() {
      alert('Upgrade to Premium to access more features!');
    }
  }
}
</script>

<style scoped>
</style>
