<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white h-full overflow-x-auto md:overflow-x-visible md:h-[calc(100vh-140px)]">
    <div class="flex flex-wrap justify-between items-center mb-4">
      <h1 class="text-2xl font-bold">{{ $t('overview').capitalize() }}</h1>
      <button-component button-text="create program" button-color="secondary" prefix-icon="fi fi-rr-browser" @click.prevent="createProgram()" v-if="useAuthStore.permissions?.includes('class_weeks_create')"
              class=" md:h-10 md:w-auto w-8 h-8 ml-auto bg-FLregistration text-white font-bold py-2 px-4 rounded-full flex items-center">
      </button-component>

    </div>
    <div v-if="!useAgendaStore.programsLoading">
      <div v-if="programs.length <= 0" class="mx-auto my-auto">
        <h1 class="text-xl">{{ $t('there are no programs').capitalize() }}</h1>
      </div>
      <div v-else class="grid grid-cols-1 md:grid-cols-5 gap-4">
        <div v-for="program in programs" :key="program.title"
             class="col-span-1 pb-2">
          <card title-size="lg"
                class="bg-white hover:border-bg-300 hover:cursor-pointer hover:shadow-lg hover:scale-105 px-4 border border-bg-100 h-52 md:h-44 overflow-hidden"
                :showImage="program.showImage" :title="program.title"
                :showIconTitle="true"
                show-permission="class_weeks_view"
                update-permission="class_weeks_update"
                delete-permission="class_weeks_update"
                icon-name="fi fi-rr-calendar-day"
                @click="openProgram($event, program)"
                @edit="createProgram()"
                @delete="deleteProgram(program.uuid)"
                :description="program.description"
          >
            <hr class="mt-4 mb-2">
            <div class="text-sm font-extrabold">
              <icon-component icon="fi fi-rr-book-copy"/>
              {{ $t('amount of lessons').capitalize() }}: {{program.books.length}}
              <div>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div v-else class="grid grid-cols-1 md:grid-cols-4 gap-4">
      <div v-for="i in 4" :key="i">
        <SkeletonLoader :style="'width: 100%; border-radius: 0.5rem; height: 13rem; display:block'" />
      </div>
    </div>
  </div>
  <edit-agenda-view-component/>

</template>

<script>
import Card from "@/components/CardComponent.vue";
import {agendaStore} from "@/views/agenda/stores/agenda.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import IconComponent from "@/components/forms/IconComponent.vue";
import EditAgendaViewComponent from "@/views/agenda/EditAgendaView.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
export default {
  name: 'OverviewAgendaComponent',
  components: {ButtonComponent, EditAgendaViewComponent, IconComponent, Card},

  setup() {
    const useAgendaStore = agendaStore();
    const useAuthStore= authStore();
    return {
      useAgendaStore,
      useAuthStore
    }
  },

  props: {
    programs: {
      required: true,
      type: Array
    }
  },
  methods: {
    openProgram($e, program) {
      this.useAgendaStore.program = program;
      this.navigateTo($e, '/agenda/' + program.uuid)
    },
    createProgram() {
      this.useAgendaStore.showEditProgramModal = true
      this.useAgendaStore.resetForm();
      this.useAgendaStore.editMode = false
    },
    deleteProgram(programUuid) {
      this.useAgendaStore.deleteProgram(programUuid);

    }
  }
}
</script>
