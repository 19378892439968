<template>
  <div>
    <div @click="toggleCollapse">
      <div v-if="filter.filterType" class="mb-1" >
        <label class="flex items-center space-x-2">
          <input v-if="filter.filterType === 'checkbox'" type="checkbox" :id="filter.name" class="form-checkbox h-5 w-5  hover:cursor-pointer">
          <span v-if="filter.filterType !== 'child'" class="text-md font-semibold hover:cursor-pointer">{{ filter.name }}</span>
          <span v-if="hasChildren" class="transform duration-500 inline-flex items-center hover:cursor-pointer" :class="{'-rotate-90': isCollapsed}"><i class="fi fi-rr-angle-small-down text-lg pt-2"></i></span>
        </label>
      </div>
    </div>

    <div v-if="filter.filterType === 'child'" class="pl-6 mb-1">
      <label class="flex items-center space-x-2">
        <input type="checkbox" :id="filter.name" class="form-checkbox h-4 w-4" :checked="filter.checked" @change="setCheck(filter)">
        <span>{{ filter.name }}</span>
      </label>
    </div>

    <!-- Recursive v-for for nested filters, with indentation and connection line -->
    <div v-if="hasChildren"
         :class="isCollapsed ? 'max-h-0 opacity-0' : 'max-h-xl opacity-100'"
         class="overflow-hidden transition-all duration-500 ease-in-out border-l-2 border-dashed border-gray-200 pl-4 mt-2">
      <filter-item
          v-for="(nestedFilter, index) in filter.filter"
          :key="index"
          :filter="nestedFilter">
      </filter-item>
    </div>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store";

export default {
  name: 'FilterItem',
  props: ['filter'],
  setup(){
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    }
  },
  data() {
    return {
      isCollapsed: false,
    }
  },
  methods: {
    async setCheck(filter) {
      filter.checked = !filter.checked;
      await this.useLibraryStore.setQueryFilters('categories');
      await this.useLibraryStore.fetchAllBooks();

    },
    toggleCollapse() {
      if (this.hasChildren) {
        this.isCollapsed = !this.isCollapsed;
      }
    },
  },
  computed: {
    hasChildren() {
      return this.filter.filter && this.filter.filter.length;
    },
  }
}
</script>
