<template>
    <div class="grid grid-cols-1 gap-4 p-6">
      <show-agenda-component :program="program"/>
    </div>
</template>


<script>


import ShowAgendaComponent from "@/views/agenda/components/ShowAgendaComponent.vue";
import {agendaStore} from "@/views/agenda/stores/agenda.store";
import {computed} from "vue";

export default {
  name: 'ShowAgendaView',
  components: {ShowAgendaComponent},

  setup() {
    const program = computed(() => useAgendaStore.program);
    const useAgendaStore = agendaStore();
    return {
      useAgendaStore,
      program
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.fetchProgram();
  },

  methods: {
    async fetchProgram() {

      await this.useAgendaStore.fetchProgram(this.$route.params.uuid);

    }
  }

}
</script>