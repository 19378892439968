import {defineStore} from "pinia";
import categoryService from "@/views/categories/services/category.service";
import {showSuccessAlert} from "@/helpers/sweetAlert";

export const categoryStore = defineStore('categoryStore', {
    state: () => ({
        categories: [],
        selectedCategoryId: '',
        form: {
            name: '',
            text_color: '',
            background_color: '',
            subcategories: []
        },
        showEditCategoryModal: false,
        editCategoriesLoading: false,
        categoriesListLoading: true,
        selectedMainCategory: '',
        editMode: false,
        subcategoriesToDelete: [],

    }),
    actions: {
        async fetchAllCategories() {
            this.categoriesListLoading = true;
            try {
                const response = await categoryService.getAllCategories();
                this.categories = response.data.data;
                this.categoriesListLoading = false;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async deleteCategory() {
            this.categoriesListLoading = true;
            try {
                let form = {
                    id: this.selectedCategoryId
                }
                await categoryService.deleteCategory(form).then(async () => {
                    await this.fetchAllCategories();
                    this.categoriesListLoading = false;
                });
            } catch (error) {
                console.error('Error deleting category:', error);
            }
        },
        async fetchCategoryById() {
            try{
                await categoryService.fetchCategory(this.selectedCategoryId).then((response) => {
                    this.form = response.data.data;
                });
            } catch(error){
                console.error('Error fetching category:', error);
            }
        },
        async updateCategory() {
            this.editCategoriesLoading = true;
            try {
                if (this.subcategoriesToDelete.length > 0) {
                    await Promise.all(this.subcategoriesToDelete.map(async (id) => {
                        await categoryService.deleteCategory({ id });

                    }));

                }

                this.subcategoriesToDelete = [];

                await categoryService.updateCategory(this.form, this.selectedCategoryId).then(async () => {
                    await this.fetchAllCategories();
                    this.closeModal();
                    this.editCategoriesLoading = false;

                    await showSuccessAlert('success', 'category updated', {}, {})

                });


            } catch (error) {
                console.error('Error updating category:', error);
                this.editCategoriesLoading = false;
            }
        },

        async createCategory() {
            this.editCategoriesLoading = true;
            try {
                await categoryService.postCategory(this.form).then(async () => {
                    await this.fetchAllCategories();
                    this.closeModal();
                    this.editCategoriesLoading = false;
                    await showSuccessAlert('success', 'category created', {}, {})
                });
            } catch (error) {
                console.error('Error creating category:', error);
                this.editCategoriesLoading = false;
            }
        },
        closeModal() {
            this.showEditCategoryModal = false;
            this.form = {
                name: '',
                subcategories: []
            };
            this.editMode = false;
        },
        openEditModal() {

            this.fetchCategoryById().then( () => {
                this.showEditCategoryModal = true;
                this.editMode = true;
            })

        },
    }
});
