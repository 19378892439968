<template>
  <!-- Main container -->
  <div class="w-full flex flex-col items-center p-4 sm:p-8">
    <!-- Logo -->
    <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo" class="mb-4 sm:mb-8 ml-4 self-center">

    <div class="w-full max-w-4xl">
      <h1 class="text-xl mb-4 text-center">Welkom bij Fluitend Leren</h1>
      <h2 class="text-2xl mb-2 text-center">{{ $t('create demo account').capitalize() }}</h2>
      <p class="text-center mb-20">
        <button @click="goToLogin" class="underline text-black">Terug naar Inloggen</button>
      </p>

      <div class="w-full justify-center mb-8">
        <!-- Step Counter -->
        <div class="text-center mb-4">
          {{ $t('step').capitalize() + ' ' + currentStep + ' ' + $t('of') + ' ' + totalSteps }}
        </div>
        <!-- Progress Bar -->
        <div class="w-full bg-gray-200 rounded-full h-1.5 relative">
          <div ref="progressBar" class="bg-FLregistration h-1.5 rounded-full absolute"></div>
        </div>
      </div>

      <swiper ref="mySwiper" :slides-per-view="1" :space-between="30" @swiper="onSwiper" @slideChange="onSlideChange">
        <!-- Swiper Slides -->
        <swiper-slide>
          <select-component class="w-full" v-model="useAuthStore.demoForm.selectedDescribe"
                            :options="optionsDescribe" input-color="primary" label="What describes you best?"
                            @change="handleSelectChange"/>
          <input-component v-if="showInputDescribe" v-model="useAuthStore.demoForm.elseDescribe" placeholder="Specify 'else'"
                           class="w-full" label="Please specify" @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="(useAuthStore.demoForm.selectedDescribe && !showInputDescribe) || (useAuthStore.demoForm.elseDescribe && showInputDescribe)">
          <input-component class="w-full" v-model="useAuthStore.demoForm.email" input-type="email"
                           input-color="primary" placeholder="john.doe@example.com" label="Email"
                           @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="useAuthStore.demoForm.email">
          <input-component class="w-full" v-model="useAuthStore.demoForm.firstname" input-type="text"
                           input-color="primary" placeholder="John" label="First Name"
                           @change="goNext"/>
          <input-component class="w-full" v-model="useAuthStore.demoForm.lastname" input-type="text"
                           input-color="secondary" placeholder="Doe" label="Last Name"
                           @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="useAuthStore.demoForm.lastname">
          <select-component class="w-full" v-model="useAuthStore.demoForm.selectedSource"
                            :options="optionsSource" input-color="secondary" label="Where did you find us?"
                            @change="handleSelectChange" />
          <input-component v-if="showInputSource" v-model="useAuthStore.demoForm.elseSource" placeholder="Specify 'else'"
                           class="w-full" label="Please specify" @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="(useAuthStore.demoForm.selectedSource && !showInputSource) || (useAuthStore.demoForm.elseSource && showInputSource)">
          <select-component class="w-full" v-model="useAuthStore.demoForm.selectedMath"
                            :options="optionsMath" input-color="primary" label="What math methods?"
                            @change="handleSelectChange"/>
          <input-component v-if="showInputMath" v-model="useAuthStore.demoForm.elseMath" placeholder="Specify 'else'"
                           class="w-full" label="Please specify" @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="(useAuthStore.demoForm.selectedMath && !showInputMath) || (useAuthStore.demoForm.elseMath && showInputMath)">
          <select-component class="w-full" v-model="useAuthStore.demoForm.selectedLanguage"
                            :options="optionsLanguage" input-color="secondary" label="What language methods?"
                            @change="handleSelectChange"/>
          <input-component v-if="showInputLanguage" v-model="useAuthStore.demoForm.elseLanguage"
                           placeholder="Specify 'else'"
                           class="w-full" label="Please specify" @change="goNext"/>
        </swiper-slide>
        <swiper-slide v-if="(useAuthStore.demoForm.selectedLanguage && !showInputLanguage) || (useAuthStore.demoForm.elseLanguage && showInputLanguage)">
          <textarea-component v-model="useAuthStore.demoForm.reason" label="What is the reason for the demo?"
                              placeholder="Your reason" class="w-full" input-color="secondary"/>

          <div class="mt-4">
            <label class="flex items-center space-x-2">
              <input type="checkbox" v-model="useAuthStore.demoForm.acceptedPolicies" />
              <span class="text-sm">
                I have read and accept the
                <button @click="showPrivacyPolicy = true" class="underline text-black">Privacybeleid</button>
              </span>
            </label>
          </div>
        </swiper-slide>
      </swiper>

      <div class="flex justify-center items-center mt-4 space-x-4">
        <button @click="goPrev" class="text-lg font-bold p-2"><icon-component icon="fi fi-br-arrow-left"></icon-component></button>
        <button-component :is-loading="useAuthStore.isLoading" type="submit"
                          class="shadow-lg px-10 py-2 w-full" buttonText="Probeer Demo" buttonColor="primary" :always-show-text="true"
                          :disabled="!isFormComplete" @click="submitDemoForm"/>
        <button @click="goNext" class="text-lg font-bold p-2"><icon-component icon="fi fi-br-arrow-right"></icon-component>
        </button>
      </div>
    </div>

    <!-- Privacy Policy Modal -->
    <privacy-policy v-if="showPrivacyPolicy" @close="showPrivacyPolicy = false" />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore from 'swiper';
import SelectComponent from "@/components/forms/SelectComponent.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import TextareaComponent from "@/components/forms/TextareaComponent.vue";
import { authStore } from "@/views/auth/stores/auth.store";
import IconComponent from "@/components/forms/IconComponent.vue";
import PrivacyPolicy from "@/views/auth/PrivacyPolicy.vue";
import gsap from 'gsap';

SwiperCore.use([]);

export default {
  name: 'RegisterComponent',
  components: { IconComponent, InputComponent, ButtonComponent, SelectComponent, Swiper, SwiperSlide, TextareaComponent, PrivacyPolicy },
  setup() {
    const useAuthStore = authStore();
    const mySwiper = ref(null);
    const progressBar = ref(null);

    const totalSteps = computed(() => 7);
    const currentStep = ref(1);

    return { useAuthStore, mySwiper, progressBar, totalSteps, currentStep };
  },
  data() {
    return {
      optionsDescribe: [
        { value: 'school leader / intern supervisor', text: 'school leader / intern supervisor' },
        { value: 'teacher', text: 'teacher' },
        { value: 'parent', text: 'parent' },
        { value: 'upper school board', text: 'upper school board' },
        { value: 'working group with decision-making authority', text: 'working group with decision-making authority' },
        { value: 'else', text: 'else' }
      ],
      optionsSource: [
        { value: 'google', text: 'google' },
        { value: 'email', text: 'email' },
        { value: 'phone', text: 'phone' },
        { value: 'social media', text: 'social media' },
        { value: 'acquaintances and friends', text: 'acquaintances and friends' },
        { value: 'else', text: 'else' }
      ],
      optionsMath: [
        { value: 'world in numbers', text: 'world in numbers' },
        { value: 'plus point', text: 'plus point' },
        { value: 'number and space junior', text: 'number and space junior' },
        { value: 'everything counts Q', text: 'everything counts Q' },
        { value: 'snappet', text: 'snappet' },
        { value: 'else', text: 'else' }
      ],
      optionsLanguage: [
        { value: 'staal', text: 'staal' },
        { value: 'language active', text: 'language active' },
        { value: 'new dutch junior', text: 'new dutch junior' },
        { value: 'taaljacht', text: 'taaljacht' },
        { value: 'snappet', text: 'snappet' },
        { value: 'else', text: 'else' }
      ],
      elseDescribe: '',
      elseSource: '',
      elseMath: '',
      elseLanguage: '',
      showPrivacyPolicy: false,
    };
  },
  computed: {
    showInputDescribe() {
      return this.useAuthStore.demoForm.selectedDescribe === 'else';
    },
    showInputSource() {
      return this.useAuthStore.demoForm.selectedSource === 'else';
    },
    showInputMath() {
      return this.useAuthStore.demoForm.selectedMath === 'else';
    },
    showInputLanguage() {
      return this.useAuthStore.demoForm.selectedLanguage === 'else';
    },
    isFormComplete() {
      const basicFieldsComplete = this.useAuthStore.demoForm.selectedDescribe &&
          this.useAuthStore.demoForm.firstname &&
          this.useAuthStore.demoForm.lastname &&
          this.useAuthStore.demoForm.email &&
          this.useAuthStore.demoForm.selectedSource &&
          this.useAuthStore.demoForm.selectedMath &&
          this.useAuthStore.demoForm.selectedLanguage &&
          this.useAuthStore.demoForm.reason;

      const elseFieldsComplete =
          (!this.showInputDescribe || this.useAuthStore.demoForm.elseDescribe.trim() !== '') &&
          (!this.showInputSource || this.useAuthStore.demoForm.elseSource.trim() !== '') &&
          (!this.showInputMath || this.useAuthStore.demoForm.elseMath.trim() !== '') &&
          (!this.showInputLanguage || this.useAuthStore.demoForm.elseLanguage.trim() !== '');

      return basicFieldsComplete && elseFieldsComplete && this.useAuthStore.demoForm.acceptedPolicies;
    }
  },
  methods: {
    goPrev() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slidePrev();
        this.updateProgressBar();
      }
    },
    goNext() {
      if (this.mySwiper.value) {
        this.mySwiper.value.slideNext();
        this.updateProgressBar();
      }
    },
    validateInput() {
      const shouldAdvance = (!this.showInputDescribe || this.elseDescribe.trim() !== '') &&
          (!this.showInputSource || this.elseSource.trim() !== '') &&
          (!this.showInputMath || this.elseMath.trim() !== '') &&
          (!this.showInputLanguage || this.elseLanguage.trim() !== '');

      if (shouldAdvance && this.mySwiper.value) {
        this.goNext();
      }
    },
    updateProgressBar() {
      const step = this.mySwiper.value ? this.mySwiper.value.activeIndex + 1 : 1;
      const newWidth = (step / this.totalSteps) * 100;
      gsap.to(this.$refs.progressBar, { width: `${newWidth}%`, duration: 0.5 });
      this.currentStep = step;
    },
    submitDemoForm() {
      if (this.isFormComplete) {
        this.useAuthStore.isLoading = true;
        this.useAuthStore.createDemoForm();
      }
    },
    onSwiper(swiper) {
      this.mySwiper.value = swiper;
      this.updateProgressBar();
    },
    onSlideChange() {
      this.updateProgressBar();
    },
    handleSelectChange() {
      this.validateInput();
      if (!this.showInputDescribe && !this.showInputSource && !this.showInputMath && !this.showInputLanguage) {
        this.goNext();
      }
    },
    goToLogin() {
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.transition-width {
  transition: width 0.5s ease-in-out;
}
</style>
