import {defineStore} from "pinia";
import userService from "@/views/users/services/user.service";
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {showSuccessAlert} from "@/helpers/sweetAlert";

export const userStore = defineStore('userStore', {
    state: () => {
        return {
            users: [],
            user: {},
            selectedUserId: '',
            editMode: false,
            editUserBooksMode: false,
            form: {
                first_name: '',
                last_name: '',
                email: '',
                role: '',
            },
            upgradeForm: {
                phone_number: ''
            },
            workspaceBooksForm: {
                book_uuids: [],
                workspace_uuid: ''
            },
            workspaceProgramsForm: {
                program_uuids: [],
                workspace_uuid: ''
            },
            workspaceForm: {
                companyName: '',
                coc: '',
                vat: '',
                name: '',
                description: '',
                image_name: '',
                image: null,
                url: window.location.origin
            },
            showEditUserModal: false,
            openUserModal: false,
            showEditUserBooksModal: false,
            showEditUserProgramsModal: false,
            editUseProgramsMode: false,
            openUserBooksModal: false,
            isLoading: false,
            isLoadingUsers: true,
            isLoadingUserBooks: false,
            isLoadingUserSettings: false,
            disabled: false,
            selectedWorkspaceUuid: '',
            selectedWorkspaceBooks: [],
            selectedWorkspacePrograms: [],
            roles: []
        }
    },
    actions: {
        async fetchAllUsers() {
            await userService.getAllUsers().then(response => {
                this.users = response.data.data;
                this.isLoadingUsers = false;
            });
        },

        async createUser() {
            this.isLoading = true;
            this.disabled = true;
            await userService.postUser(this.form).then(async (response) => {
                let useAppStore = appStore();
                useAppStore.setError(response.data.message, 'success');
                await this.closeModal();
                await this.fetchAllUsers();
            });
        },

        async deleteUser() {
            try {
                let data = {uuid: this.selectedUserId};
                await userService.deleteUser(data).then(async () => {
                    await this.fetchAllUsers();
                });
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        },

        async updateUserRole() {
            try {
                let data = {userId: this.selectedUserId};
                await userService.updateUserRole(data).then(async () => {
                    await this.fetchAllUsers();
                });
            } catch (error) {
                console.error('Error upgrading user:', error);
            }
        },

        async denyUserRequest() {
            try {
                const useAuthStore = authStore()
                let data = {
                    userId: this.selectedUserId,
                    workspaceId: useAuthStore.workspace.uuid

                };
                await userService.denyUserRequest(data).then(async () => {
                    await this.fetchAllUsers();
                });
            } catch (error) {
                console.error('Error denying user:', error);
            }
        },

        async createWorkspaceBooks() {
            this.isLoadingUserBooks = true;
            this.disabled = true;
            this.workspaceBooksForm.workspace_uuid = this.selectedWorkspaceUuid;
            await userService.postUserBooks(this.workspaceBooksForm).then(async () => {
                await this.closeModal();
                await this.fetchAllUsers();
                showSuccessAlert('success', 'lessons successfully attached');

                this.isLoadingUserBooks = false;
            });
        },

        async createWorkspacePrograms() {
            this.isLoadingUserPrograms = true;
            this.disabled = true;
            this.workspaceProgramsForm.workspace_uuid = this.selectedWorkspaceUuid;
            await userService.postUserPrograms(this.workspaceProgramsForm).then(async () => {
                await this.closeModal();
                await this.fetchAllUsers();
                showSuccessAlert('success', 'lessons successfully attached');

                this.isLoadingUserPrograms = false;
            });
        },

        async requestUserUpgrade() {
                const useAuthStore = authStore();
                const form = {
                    'workspaceId': useAuthStore?.workspace?.uuid,
                    'userId': useAuthStore?.user?.uuid,
                    'phoneNumber': this.upgradeForm.phone_number
                }

                await userService.sendUserUpgrade(form).then(() => {
                    const useAppStore = appStore();
                    useAppStore.showUpgradeFormModal = false
                });

        },


        async getRoles() {
            await userService.getRoles().then(response => {
                this.roles = response.data.data.map(role => {
                    return {
                        value: role.name,
                        text: role.name
                    };
                });
            });
        },

        async deleteWorkspace(uuid) {
            try {
                await userService.deleteWorkspace({"uuid": uuid}).then(() => {
                    const useAuthStore = authStore();
                    useAuthStore.workspace = {};
                    useAuthStore.logout();
                });
            } catch (error) {
                console.error('Error deleting workspace:', error);
            }
        },

        async createWorkspace() {
            try {
                await userService.postWorkspace(this.workspaceForm).then(async (response) => {
                    let workspace = response.data.data;
                    authStore().setWorkspaceCookie(workspace);
                    if (this.workspaceForm.image) {
                        let formDataImg = new FormData();
                        formDataImg.append('image', this.workspaceForm.image);
                        formDataImg.append('workspace_uuid', workspace.uuid);
                        await userService.uploadLogo(formDataImg).then(() => {
                            window.location = '/';
                        });
                    } else {
                        window.location = '/';
                    }
                });
            } catch (error) {
                console.error('Failed creating workspace', error);
            }
        },

        async updateWorkspace() {
            try {
                let useAuthStore = authStore();
                this.isLoading = true;
                this.disabled = true;
                let workspaceForm = useAuthStore.workspace;
                const updateData = {
                    'first_name': this.form.first_name,
                    'last_name': this.form.last_name,
                    'workspace_id': workspaceForm.uuid,
                    'name': workspaceForm.name,
                    'description': workspaceForm.description,
                };
                await userService.updateWorkspace(updateData).then(async (response) => {
                    let workspace = response.data.data;
                    authStore().setWorkspaceCookie(workspace);
                    if (this.workspaceForm.image) {
                        let formDataImg = new FormData();
                        formDataImg.append('image', this.workspaceForm.image);
                        formDataImg.append('workspace_uuid', workspaceForm.uuid);
                        await userService.uploadLogo(formDataImg).then(() => {
                            window.location = '/';
                        });
                    } else {
                        window.location = '/';
                    }
                });
            } catch (error) {
                console.error('Failed updating workspace', error);
            } finally {
                this.isLoading = false;
                this.disabled = false;
            }
        },

        closeModal() {
            this.showEditUserModal = false;
            this.showEditUserBooksModal = false;
            this.showEditUserProgramsModal = false;
            this.isLoading = false;
            this.isLoadingUserBooks = false;
            this.isLoadingUserPrograms = false;
            this.disabled = false;
            this.form = {
                first_name: '',
                last_name: '',
                email: '',
                role: '',
                street_name: '',
                city: '',
                zipcode: '',
                house_number: '',
            };
        },
    },
});
