<template>
  <div v-if="visible" :class="['bg-FLregistration text-white', type]" class="p-3 my-4 rounded-md shadow-lg">
    <p>{{ message }}    <button @click="close" class="ml-4 text-white">&times;</button>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'error',
    },
    duration: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      visible: true,
    };
  },
  mounted() {
    if (this.duration > 0) {
      setTimeout(this.close, this.duration);
    }
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    },
  },
};
</script>