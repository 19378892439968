import {createRouter, createWebHistory} from 'vue-router';
import Dashboard from '../views/dashboard/DashboardView.vue';
import Login from '../views/auth/LoginView.vue';
import Register from '../views/auth/RegisterView.vue';
import Recover from '../views/auth/RecoverView.vue';
import CreateWorkspace from '../views/auth/CreateWorkspaceView.vue';
import {getLogin, getUserProfile, checkPermission, getWorkspace, getEmailVerification} from '@/auth';
import LibraryRoute from "@/router/library/index";
import UsersRoute from "@/router/users/index";
import AgendaRoute from "@/router/agenda/index";
import i18n from "@/i18n";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import SettingRoute from "@/router/settings/index";
import CategoryRoute from "@/router/categories/index";
import EmailConfirm from "@/views/auth/EmailConfirm.vue";
import EmailVerifyWall from "@/views/auth/EmailVerifyWall.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";



const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: i18n.global.t('login page'),
            permission: false
        }
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: i18n.global.t('reset password'),
            permission: false
        }
    },
    {
        path: '/email/verify/:token',
        name: 'EmailConfirm',
        component: EmailConfirm,
        meta: {
            title: i18n.global.t('confirm email'),
            permission: false
        }
    },
    {
        path: '/email/verify',
        name: 'EmailVerifyWall',
        component: EmailVerifyWall,
        meta: {
            title: i18n.global.t('verify email'),
            permission: false
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            title: i18n.global.t('dashboard'),
            permission: true

        }
    },

    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            title: i18n.global.t('register page'),
            permission: false
        }
    },
    {
        path: '/recover',
        name: 'Recover',
        component: Recover,
        meta: {
            title: i18n.global.t('recover page'),
            permission: false

        },
    },
    {
        path: '/create-workspace',
        name: 'CreateWorkspace',
        component: CreateWorkspace,
        meta: {
            title: i18n.global.t('create workspace'),
            permission: false

        },
    },
    {
        path: '/404',
        name: 'NotFoundPage',
        component: NotFoundPage,
        meta: {
            permission: false

        },
    },
    ...AgendaRoute,
    ...UsersRoute,
    ...LibraryRoute,
    ...SettingRoute,
    ...CategoryRoute,

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    document.title = 'Fluitend Leren - Gemakkelijk Bewegend Leren Binnen Jouw Onderwijs'
    const publicPages = ['Login', 'Register', 'Recover', 'CreateWorkspace', 'ResetPassword', 'EmailConfirm', 'EmailVerifyWall'];
    const authRequired = !publicPages.includes(to.name);
    const goingToLoginPage = to.name === 'Login';

    if (authRequired && !getLogin()) {
        next({name: 'Login'});
    } else if (goingToLoginPage && getLogin()) {
        return next({name: 'Dashboard'});
    } else {
        if (to.meta.permission) {
            const workspace = await getWorkspace();

            await getUserProfile();
            const emailVerification = getEmailVerification();
            //Conver emailverification JSON String to JSON object
            const emailVerificationObject = JSON.parse(emailVerification);
            if(emailVerificationObject && !emailVerificationObject.email_verified_at){
                return next({name: 'EmailVerifyWall'})
            }
            if (!workspace){
                return next({name: 'CreateWorkspace'})
            }

            if (!await checkPermission(to.meta.permission) && to.meta.permission !== true) {
                return next({name: 'Dashboard'})
            }
        }
        next();
    }
});

export default router;