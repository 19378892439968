<template>
  <router-view>
    <div class="grid-cols-1 grid gap-4 p-6 overflow-x-auto md:overflow-y-auto">
          <overview-user-component :users="users"/>
          <edit-user-view v-if="useUserStore.showEditUserModal"/>
          <edit-user-books-view-component :workspace-uuid="useUserStore.selectedWorkspaceUuid" :workspace-books="useUserStore.selectedWorkspaceBooks" v-if="useUserStore.showEditUserBooksModal"/>
          <edit-user-programs-view :workspace-uuid="useUserStore.selectedWorkspaceUuid" :workspace-programs="useUserStore.selectedWorkspacePrograms" v-if="useUserStore.showEditUserProgramsModal"/>
    </div>
  </router-view>
</template>


<script>
import OverviewUserComponent from "@/views/users/components/OverviewUserComponent.vue";
import {userStore} from "@/views/users/stores/user.store.js";
import { computed } from 'vue';
import EditUserView from "@/views/users/EditUserView.vue";
import EditUserBooksViewComponent from "@/views/users/EditUserBooksView.vue";
import EditUserProgramsView from "@/views/users/EditUserProgramsView.vue";

export default {
  name: 'ShowAllUsers',
  components: {EditUserBooksViewComponent, EditUserProgramsView,  EditUserView, OverviewUserComponent},

  setup() {
    const useUserStore = userStore();

    const users = computed (() => useUserStore.users.map(user => (user)));
    return {
      useUserStore,
      users
    }
  },

  data() {
    return {
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      await this.useUserStore.fetchAllUsers();
      this.users = this.useUserStore.users;

    }
  }

}
</script>