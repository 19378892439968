<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white w-full overflow-x-auto md:h-[calc(100vh-140px)]">
    <div class="flex justify-between items-center">
      <div>
        {{ $t('amount of users').capitalize() }}: {{ users.length }}
      </div>
      <div>
        <button-component @click="openCreateModal()" v-if="useAuthStore.permissions.includes('users_create')"
                          buttonText="Create user" buttonColor="secondary" prefix-icon="fi fi-rr-user-add"/>
      </div>
    </div>
    <table class="w-full leading-normal hidden md:table">
      <thead>
      <tr>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('first name').capitalize() }}
        </th>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('last name').capitalize() }}
        </th>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('email').capitalize() }}
        </th>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('workspace name').capitalize() }}
        </th>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('role').capitalize() }}
        </th>
        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
          {{ $t('actions').capitalize() }}
        </th>
      </tr>
      </thead>
      <tbody v-if="!useUserStore.isLoadingUsers">
      <tr v-for="user in users" :key="user?.uuid" class="hover:bg-gray-100">
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          {{ user?.first_name }}
        </td>
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          {{ user?.last_name }}
        </td>
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          {{ user?.email }}
        </td>
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <div v-if="user?.workspace && user.workspace.length > 0">
            {{ user.workspace[0]?.name }}
          </div>
          <div v-else>
            <icon-component icon="fa-solid fa-xmark" class="text-red-500"/>
          </div>
        </td>
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          {{ user?.roles && user?.roles.length > 0 ? $t(user?.roles[0]?.name.toLowerCase()).capitalize() : '' }}
        </td>
        <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
          <button
              v-if="(useAuthStore?.user?.workspace.length > 0 &&
                      useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                      user?.workspace && user?.workspace?.length > 0 &&
                      useAuthStore?.user?.workspace[0]?.uuid === user.workspace[0]?.uuid &&
                      user?.roles[0]?.name === 'demo_user'"
              @click="confirmUpdateUser(user?.uuid, user?.request_upgrade)"
              class="ml-2 inline-flex text-lg text-FLregistration hover:text-FLregistrationlight">
            <icon-component icon="fi fi-sr-up"></icon-component>
          </button>
          <button
              v-if="(useAuthStore?.user?.workspace.length > 0 &&
                      useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                      user?.roles[0]?.name === 'school_director'"
              @click="confirmUpdateUser(user?.uuid)"
              class="ml-2 inline-flex text-lg text-FLregistration hover:text-FLregistrationlight">
            <icon-component icon="fi fi-sr-down"></icon-component>
          </button>
          <button
              v-if="(useAuthStore?.user?.workspace[0]?.books?.length > 0 &&
                     useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                    user?.workspace && user.workspace.length > 0 &&
                    useAuthStore?.user?.workspace[0]?.uuid !== user.workspace[0]?.uuid"
              @click="openLinkBooksModal(user.workspace[0]?.uuid, user.workspace[0]?.books)"
              class="inline-flex items-center text-lg text-FLregistrationlight hover:text-FLregistration mx-2">
            <i class="fi fi-sr-book-alt"></i>
          </button>
          <button
              v-if="(useAuthStore?.user?.workspace[0]?.programs?.length > 0 &&
                     useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                    user?.workspace && user.workspace.length > 0 &&
                    useAuthStore?.user?.workspace[0]?.uuid !== user.workspace[0]?.uuid"
              @click="openLinkProgramsModal(user.workspace[0]?.uuid, user.workspace[0]?.programs)"
              class="ml-2 inline-flex items-center text-lg text-FLregistrationlight hover:text-FLregistration mx-2">
            <i class="fi fi-sr-calendar-lines"></i>
          </button>
          <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                  @click="confirmDeleteUser(user?.uuid)"
                  class="ml-2 inline-flex items-center text-lg text-red-500 hover:text-red-600 mx-2">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <!-- loop 5 times -->
      <tr v-for="i in 5" :key="i">
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
        <td class="p-5">
          <SkeletonLoader height="20" :style="'width: 100%; display:block'" />
        </td>
      </tr>
      </tbody>
    </table>
    <!-- Mobile Version -->
    <div class="block md:hidden" v-if="!useUserStore.isLoadingUsers">
      <div v-for="user in users" :key="user?.uuid" class="border rounded-lg shadow-lg p-4 mb-4 bg-white">
        <div class="flex justify-between items-center">
          <div>
            <strong>{{ user?.first_name }} {{ user?.last_name }}</strong>
          </div>
          <div class="">
            <button
                v-if="(useAuthStore?.user?.workspace[0]?.books?.length > 0 &&
                     useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                    user?.workspace && user.workspace.length > 0 &&
                    useAuthStore?.user?.workspace[0]?.uuid !== user.workspace[0]?.uuid"
                @click="openLinkBooksModal(user.workspace[0]?.uuid, user.workspace[0]?.books)"
                class="inline-flex items-center text-lg text-FLregistrationlight hover:text-FLregistration mx-2">
              <i class="fi fi-sr-book-alt"></i>
            </button>
            <button
                v-if="(useAuthStore?.user?.workspace[0]?.programs?.length > 0 &&
                     useAuthStore?.user?.roles[0]?.name === 'reseller_admin') &&
                    user?.workspace && user.workspace.length > 0 &&
                    useAuthStore?.user?.workspace[0]?.uuid !== user.workspace[0]?.uuid"
                @click="openLinkProgramsModal(user.workspace[0]?.uuid, user.workspace[0]?.programs)"
                class="ml-2 inline-flex items-center text-lg text-FLregistrationlight hover:text-FLregistration mx-2">
              <i class="fi fi-sr-calendar-lines"></i>
            </button>

            <button v-if="useAuthStore?.user?.uuid !== user?.uuid"
                    @click="confirmDeleteUser(user?.uuid)"
                    class="ml-2 inline-flex items-center text-lg text-red-500 hover:text-red-600 mx-2">
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
        <div class="text-sm mt-2">
          <p><strong>{{ $t('email').capitalize() }}:</strong> {{ user?.email }}</p>
          <p><strong>{{ $t('workspace name').capitalize() }}:</strong> {{ user?.workspace[0]?.name }}</p>
          <p><strong>{{ $t('role').capitalize() }}:</strong>
            {{ user?.roles && user?.roles.length > 0 ? $t(user?.roles[0]?.name.toLowerCase()).capitalize() : '' }}
          </p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userStore} from "@/views/users/stores/user.store.js";
import {authStore} from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";
import {showCustomAlert, showDeleteConfirmation} from "@/helpers/sweetAlert";

export default {
  name: 'OverviewUserComponent',
  components: {IconComponent, ButtonComponent},

  setup() {
    const useUserStore = userStore();
    const useAuthStore = authStore();

    return {
      useUserStore,
      useAuthStore
    }
  },

  props: {
    users: {
      required: true,
      type: Array
    }
  },

  methods: {
    openCreateModal() {
      this.useUserStore.showEditUserModal = true
      this.useUserStore.editMode = false
    },
    openLinkBooksModal(workspaceUuid, selectedUserBooks) {
      this.useUserStore.selectedWorkspaceBooks = selectedUserBooks
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid
      this.useUserStore.showEditUserBooksModal = true
      this.useUserStore.editUserBooksMode = false
    },
    openLinkProgramsModal(workspaceUuid, selectedUserPrograms) {
      this.useUserStore.selectedWorkspacePrograms = selectedUserPrograms
      this.useUserStore.selectedWorkspaceUuid = workspaceUuid
      this.useUserStore.showEditUserProgramsModal = true
      this.useUserStore.editUseProgramsMode = false
    },
    async confirmDeleteUser(userUuid) {
      showDeleteConfirmation(() => this.deleteUser(userUuid));
    },
    async deleteUser(userUuid) {
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.deleteUser();
    },
    async confirmUpdateUser(userUuid, userRequested){
      showCustomAlert(() => this.updateUserRole(userUuid), 'update user', 'are you sure you want to update this user?', {}, {},'success', 'successfully updated', {}, {}, userRequested ? 'deny request' : null, userRequested ? () => this.denyUserRequest(userUuid) : null);
    },

    async updateUserRole (userUuid){
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.updateUserRole();
    },
    async denyUserRequest (userUuid){
      this.useUserStore.selectedUserId = userUuid;
      await this.useUserStore.denyUserRequest();
    }
  }
}
</script>
