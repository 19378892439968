import Swal from "sweetalert2";
import {i18n} from '@/i18n';


export function showDeleteConfirmation(onConfirm, {
    title = i18n.t("are you sure?").capitalize(),
    text = i18n.t("you won't be able to revert this!").capitalize(),
    confirmButtonText = i18n.t("yes, delete it").capitalize(),
    cancelButtonText = i18n.t("cancel").capitalize()
} = {}) {
    Swal.fire({
        title,
        text,
        icon: "warning",
        iconColor: "red",
        showCancelButton: true,
        confirmButtonColor: "#dc2626",
        cancelButtonColor: "lightgray",
        confirmButtonText,
        cancelButtonText,
    }).then((result) => {
        if (result.isConfirmed) {
            if (typeof onConfirm === "function") {
                const title = i18n.t("success").capitalize()
                const text = i18n.t("successfully deleted").capitalize()

                onConfirm().then(() => {
                    showSuccessAlert(title, text)

                });
            }
        }
    });
}

export function showCustomAlert(onConfirm, title, text, titleParams = {}, textParams = {}, confirmedTitle, confirmedText, confirmedTitleParams = {}, confirmedTextParams = {}, denyButtonText = null, onDeny = null, icon = "info", confirmButtonText = i18n.t("yes").capitalize(), cancelButtonText = i18n.t("cancel").capitalize()) {
    Swal.fire({
        title: i18n.t(title, titleParams).capitalize(),
        text: i18n.t(text, textParams).capitalize(),
        showCancelButton: true,
        showDenyButton: !!denyButtonText,
        icon: icon,
        confirmButtonColor: '#CB2B71FF',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        denyButtonText: denyButtonText ? i18n.t(denyButtonText).capitalize() : null,
    }).then((result) => {
        if (result.isConfirmed) {
            if (typeof onConfirm === "function") {
                onConfirm().then(() => {
                    showSuccessAlert(confirmedTitle, confirmedText, confirmedTitleParams, confirmedTextParams)
                }).catch((error) => {
                    console.error("Error during confirmation:", error);
                });
            }
        }
        if(result.isDenied){
            if(typeof onDeny === "function"){
                onDeny().then(() => {
                    showSuccessAlert(confirmedTitle, confirmedText, confirmedTitleParams, confirmedTextParams)
                }).catch((error) => {
                    console.error("Error during confirmation:", error);
                });
            }
        }
    });
}

export function showSuccessAlert(title, text, titleParams = {}, textParams = {}, timer = 3000) {
    Swal.fire({
        icon: "success",
        title: i18n.t(title, titleParams).capitalize(),
        text: i18n.t(text, textParams).capitalize(),
        timer: timer,
        showConfirmButton: false,
    });
}

export function showErrorAlert(title, text, titleParams = {}, textParams = {}, timer = 3000) {
    Swal.fire({
        icon: "error",
        title: i18n.t(title, titleParams).capitalize(),
        text: i18n.t(text, textParams).capitalize(),
        timer: timer
    })
}