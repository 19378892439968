<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white h-[calc(100vh-100px)]  md:h-[calc(100vh-140px)]">
    <h1 class="text-lg font-bold py-1 px-1"><icon-component icon="fi fi-sr-filter" class="pr-4"/>Filters</h1>

    <search-component class="mb-4"></search-component>

    <div class="md:h-[calc(100vh-300px)] h-[calc(100vh-240px)] overflow-y-scroll">
      <div v-for="(filter) in filters" :key="filter.id" class="">
        <filter-item :filter="filter"></filter-item>
      </div>
    </div>

  </div>
</template>

<script>
import FilterItem from '@/views/library/components/FilterItem.vue'
import IconComponent from "@/components/forms/IconComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";

export default {
  name: 'FilterComponent',
  components: {
    SearchComponent,
    IconComponent,
    FilterItem
  },
  props: {
    filters: {
      required: true,
      type: Array
    }
  },
  methods: {
  }
}
</script>
