<template>
  <div class="bg-white flex flex-col sm:flex-row">
    <!-- Left side with the logo and login form -->
    <div class="md:w-1/2 sm:w-full flex flex-col items-center p-4 sm:p-8">
      <!-- Logo -->
      <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo" class="mb-4 sm:mb-8 ml-4 sm:absolute sm:top-0 sm:left-0 sm:mt-4">


      <div class="items-center my-auto">
        <h1 class="text-2xl mb-2 sm:mb-4 text-center sm:text-center">Wachtwoord opnieuw instellen</h1>
        <div class="items-center">
          <form @submit.prevent="submitForgotPassword" class="mx-auto">
            <input-component v-model="store.formPasswordReset.password" input-type="password" placeholder="Nieuw wachtwoord"
                             inputColor="secondary" class="w-full" :required="true"/>
            <input-component v-model="store.formPasswordReset.password_confirmation" input-type="password" placeholder="Herhaal wachtwoord"
                             inputColor="secondary" class="w-full" :required="true"/>
            <button-component type="submit" buttonText="Verzenden" class="sm:w-full md:w-1/2 mx-auto" buttonColor="secondary"/>
            <p class="text-center text-md sm:text-lg text-gray-500">
              <a href="/login" class="underline text-black">Annuleren</a>
            </p>
          </form>
        </div>

      </div>
    </div>


    <div class="hidden sm:block sm:w-1/2">
      <img src="../../assets/RegistrationImage.png" alt="Girl Holding Book" class="w-full  sm:h-full object-cover">
    </div>
  </div>

</template>

<script>
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import { authStore } from "@/views/auth/stores/auth.store";

export default {
  name: 'ResetPassword',
  components: { InputComponent, ButtonComponent },
  data() {
    return {
      email: ''
    };
  },

  setup() {
    const store = authStore();
    return {
      store
    }
  },
  methods: {
    async submitForgotPassword() {
      this.store = authStore();
      try {
        await this.store.resetPassword();
      } catch (error) {
        error.log("Error when sending email")
      }
    }
  }
}
</script>

