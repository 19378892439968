<template>
  <div v-if="modalIsOpen" class="fixed inset-0 flex items-center justify-center z-60" @click="closeModal">
    <div class="bg-white w-80 md:h-1/8 md:w-1/4 absolute top-20 md:right-20 md:top-16 z-70 shadow-lg rounded-lg p-8" @click.stop>
      <h1 class="text-lg flex"><i class="fi fi-rr-bug mr-2 pt-0.5"></i> {{ $t('report bug').capitalize() }}</h1>
      <form @submit.prevent="submitBugReport">
        <label for="description" class="block text-sm font-medium text-gray-700 mb-2">
          {{ $t('description').capitalize() }}
        </label>
        <textarea id="description" rows="4"
                  class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md p-2"
                  :placeholder="$t('describe the bug').capitalize()"
                  v-model="store.form.description"></textarea>
        <button-component type="submit" class="mt-4 bg-FLregistration hover:bg-FLregistrationlight text-white font-bold py-2 px-4" button-color="primary" button-text="submit" prefix-icon="fi fi-br-paper-plane-top" :is-loading="store.isBugReportLoading"/>
      </form>
    </div>
  </div>
</template>

<script>
import {appStore} from "@/views/app.store";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";

export default {
  components: {ButtonComponent},
  props: {
    modalIsOpen: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const store = appStore();
    const submitBugReport = () => {
      store.isBugReportLoading = true;
      store.submitBugReport();
    };
    return {
      store,
      submitBugReport
    };
  },
  methods: {
    closeModal() {
      this.store.showBugReportModal = false;
    }
  }
};
</script>

<style scoped>
.z-60 {
  z-index: 60;
}

.z-70 {
  z-index: 70;
}
</style>
