<template>

  <div class="home">
    <dashboard-component/>
  </div>
</template>

<script>
import DashboardComponent from '@/views/dashboard/components/DashboardComponent.vue'

export default {
  name: 'HomeView',
  components: {
    DashboardComponent
  },

}
</script>