<!-- LoadingOverlay.vue -->
<template>
    <div v-if="loading" class="loading-overlay">
      <Vue3Lottie
        ref="customControl"
        :animationData="loadingAnimationData"
        :animationLink="loadingAnimationLink"
        :height="250"
        :width="250"
        :loop="true"
        :speed="3"
        :autoPlay="true"
      />
    </div>
  </template>
  
  <script>
  import { Vue3Lottie } from "vue3-lottie";
  
  export default {
    props: {
      loading: {
        type: Boolean,
        required: false,
      },
      loadingAnimationData: {
        type: Object,
        default: null,
      },
      loadingAnimationLink: {
        type: String,
        default: "/loading.json",
      },
    },
    components: { Vue3Lottie },
  };
  </script>
  
  <style scoped>
  .loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .loading-overlay img {
    max-width: 100%;
    max-height: 100%;
  }
  </style>