import i18n from "@/i18n";
import ShowAllCategoriesView from "@/views/categories/ShowAllCategoriesView.vue";

export default [
    {
        path: "/categories",
        name: 'Categories',
        component: ShowAllCategoriesView,
        meta: {
            title: (i18n.global.t("categories")),
            permission: 'categories_view'
        },
    },
];
