import ShowLibrary from "@/views/library/ShowAllLibraryView.vue";
import i18n from "@/i18n";


export default [
    {
        path: "/library",
        component: ShowLibrary,
        meta: {
            title: i18n.global.t("library"),
            permission: 'books_view'
        },
    },
];
