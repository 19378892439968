<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white">
    <div class="flex flex-wrap justify-center md:justify-between items-center mb-4">
      <h1 class="text-sm md:text-2xl font-bold">{{ $t('class program').capitalize() }} {{ program.name }}</h1>
      <div class="flex mt-3 space-x-3 md:mt-0 md:space-x-0">
        <button-component
            class="mx-1"
            v-if="useAuthStore.permissions?.includes('class_weeks_update')"
            @edit="createProgram(program.books)"
            emit-name="edit"
            button-color="secondary"
            button-text="Edit"
            prefix-icon="fas fa-edit"
        ></button-component>
        <button-component
            class="mx-1"
            v-if="useAuthStore.permissions?.includes('class_weeks_delete')"
            @delete="confirmDeleteProgram"
            emit-name="delete"
            button-color="secondary"
            button-text="Delete"
            prefix-icon="fi fi-rr-trash"
        ></button-component>
      </div>
    </div>
    <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4">
      <card
          v-for="library in program.books"
          :key="library.title"
          :class="['bg-FLSecondary text-white rounded transform transition duration-200 hover:scale-105 hover:shadow-2xl hover:bg-FLPrimary hover:text-white cursor-pointer']"
          @show="openBook($event, library)"
          :is-demo="!!library.is_demo"
          :is-locked="isLocked(library.is_demo)"
          :allow-edits="true"
          :delete-detail-button="false"
          :edit-detail-button="false"
          show-permission="class_weeks_view"
          :title="library.name"
          :categories="library.categories"
          :image="library.image"
          :views="library.views"
          :show-image="true"
          title-size="md"
      >
      </card>
    </div>
    <edit-agenda-view-component :program="program"/>
  </div>
</template>

<script>
import Card from "@/components/CardComponent.vue";
import { libraryStore } from "@/views/library/stores/library.store.js";
import { agendaStore } from "@/views/agenda/stores/agenda.store.js";
import { authStore } from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import EditAgendaViewComponent from "@/views/agenda/EditAgendaView.vue";
import { showDeleteConfirmation } from "@/helpers/sweetAlert";

export default {
  name: "OverviewAgendaComponent",
  components: { EditAgendaViewComponent, ButtonComponent, Card },

  setup() {
    const useLibraryStore = libraryStore();
    const useAuthStore = authStore();
    const useAgendaStore = agendaStore();

    return {
      useAuthStore,
      useLibraryStore,
      useAgendaStore,
    };
  },

  props: {
    program: {
      required: true,
      type: Object,
    },
  },

  methods: {
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles[0]?.name === 'demo_user' && !isDemo;
    },

    createProgram() {
      this.useAgendaStore.setProgramBooks();
      this.useAgendaStore.showEditProgramModal = true;
      this.useAgendaStore.editMode = true;
    },

    async confirmDeleteProgram() {
      showDeleteConfirmation(() => this.deleteProgram());
    },
    async deleteProgram() {
      try {
        await this.useAgendaStore.deleteProgram();
      } catch (e) {
        console.error("Error deleting workspace:", e);

      }

    },
    async openBook($e, book) {
      try {
        await this.useLibraryStore.incrementViews(book.uuid);
        this.useLibraryStore.selectedBook = book;
        this.navigateTo($e, "/library/book/" + book.uuid);
      } catch (error) {
        console.error('Error opening book:', error);
      }
    },
  },
};
</script>
