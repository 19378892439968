<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white">
    <div class="grid md:grid-cols-2 grid-cols-1 gap-4">
      <div class="flex flex-col h-[calc(100vh-250px)] md:h-[calc(100vh-400px)]">
        <div class="font-bold pl-2 flex justify-between">
          <div>
            <i class="fa-solid fa-info pr-2"></i>
            <span class="text-md md:text-2xl">
              {{ useLibraryStore.book?.name }}
            </span>
          </div>
          <div class="flex items-center">

            <button-component
                prefix-icon="fa-solid fa-book"
                class="text-right md:w-auto text-sm align-right mx-2"
                buttonColor="secondary"
                buttonText="Show lesson"
                @click="() => { useLibraryStore.openBookModal = true; }"
            />
            <button-component
                prefix-icon="fa-solid fa-download"
                class="text-right md:w-auto text-sm align-right mx-2"
                buttonColor="secondary"
                buttonText="Download"
                @click="downloadBookWithWatermark"
            />
          </div>
        </div>

        <div class="flex gap-2">
          <div>
            <div class="py-2 text-xs md:text-sm hidden md:block">
              {{ $t('last updated').capitalize() }}: {{ formattedUpdatedAt }}
            </div>
            <div class="py-2 text-md md:text-sm block md:hidden">
              <icon-component icon="fi fi-br-refresh" class="text-xs"/>
              {{ formattedUpdatedAt }}
            </div>
          </div>
        </div>
        <!-- Badges for Grade and Subject -->
        <div class="gap-2 mb-4">
          <span v-for="category in book.categories" :key="category.id">
            <badge-component class="text-xs font-semibold rounded inline-block mr-2 mb-2 w-auto"
                             size="md"
                             :badge-color="category.background_color"
                             :text-color="category.text_color"
                             :badge-text="category.name

            "/>
          </span>
        </div>
        <div class="border-b-2 md:border-b-2 mb-4"/>
        <div class="grid md:grid-cols-2 grid-cols-1 h-[calc(100vh-260px)]">
          <div class="space-y-4 col-span-2">
            <div>
              <label class="block text-lg font-semibold mb-2">
                <!-- Label text here if needed -->
              </label>
              <p class="text-gray-700">
                {{ useLibraryStore.book?.description }}
              </p>
              <vue-plyr class="video-wrapper" v-if="useLibraryStore.book?.url">
                <div
                    data-plyr-provider="youtube"
                    :data-plyr-embed-id="useLibraryStore.book.url"
                >
                </div>
              </vue-plyr>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden md:inline h-[calc(100vh-250px)] overflow-auto">
        <vue-pdf-embed :source="useLibraryStore.file" class="rounded"/>
      </div>
    </div>

    <modal-show-book v-if="useLibraryStore.openBookModal"/>
  </div>
</template>

<script>
import {libraryStore} from "@/views/library/stores/library.store";
import ModalShowBook from "@/views/library/components/ModalShowBook.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import BadgeComponent from "@/components/forms/BadgeComponent.vue";
import VuePdfEmbed from "vue-pdf-embed";
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'ShowBookComponent',
  components: {
    IconComponent,
    BadgeComponent,
    VuePdfEmbed,
    ButtonComponent,
    ModalShowBook,
    VuePlyr
  },
  setup() {
    const useLibraryStore = libraryStore();


    const downloadBookWithWatermark = async () => {
      await useLibraryStore.setBookWithWatermark(useLibraryStore.book, true);
      await useLibraryStore.incrementDownlosads(useLibraryStore.book.uuid);
    };

    return {
      useLibraryStore,
      downloadBookWithWatermark
    };
  },
  mounted() {
    this.handleIncrementViews()
  },
  methods: {
    async handleIncrementViews() {
      await this.useLibraryStore.incrementViews(this.useLibraryStore?.book?.uuid);

    },
  },
  computed: {


    formattedUpdatedAt() {
      if (this.useLibraryStore.book && this.useLibraryStore.book.updated_at) {
        const date = new Date(this.useLibraryStore.book.updated_at);
        const formattedDate = date.toLocaleDateString('nl-NL', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
        const formattedTime = date.toLocaleTimeString('nl-NL', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
        return `${formattedDate} @ ${formattedTime}`;
      }
      return '';
    }
  },
  props: {
    book: {
      required: true,
      type: Object
    }
  }
}
</script>
