<template>
  <router-view>
    <div class="grid-cols-1 grid gap-4 p-6">
          <overview-categories-component :categories="categories"/>
          <edit-category-view/>
    </div>
  </router-view>
</template>


<script>
import OverviewCategoriesComponent from "@/views/categories/components/OverviewCategoriesComponent.vue";
import {categoryStore} from "@/views/categories/stores/category.store";
import { computed } from 'vue';
import EditCategoryView from "@/views/categories/EditCategoryView.vue";

export default {
  name: 'ShowAllUsers',
  components: {EditCategoryView, OverviewCategoriesComponent},

  setup() {
    const useCategoryStore = categoryStore();

    const categories = computed (() => useCategoryStore.categories.map(user => (user)));
    return {
      useCategoryStore,
      categories
    }
  },

  data() {
    return {
    }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async getCategories() {
      await this.useCategoryStore.fetchAllCategories();
      this.categories = this.useCategoryStore.categories;

    }
  }

}
</script>