import ShowUsers from "@/views/users/ShowAllUsersView.vue";
import i18n from "@/i18n";

export default [
    {
        path: "/users",
        component: ShowUsers,
        meta: {
            title: i18n.global.t("users list"),
            permission: 'users_view'
        },
    },
];
