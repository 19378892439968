<template>
  <div v-if="useUserStore.showEditUserBooksModal"
       class="fixed inset-0 bg-gray-600 bg-opacity-50 index-50 overflow-y-auto h-full w-full" @click.self="closeModal">

    <div class="relative top-56  md:top-20 mx-auto p-5 border w-screen md:w-1/3 shadow-lg rounded-md bg-white">
      <!-- Close Icon -->
      <div class="absolute top-0 right-0 pt-4 pr-4">
        <button @click="closeModal" class="text-gray-600 hover:text-gray-900">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">{{ $t('choose your lessons').capitalize() }}</h3>

      <form @submit.prevent="submitUserBooks()" class="pt-2">
        <hr>
        <checkbox-component v-for="book in books"
                            class="pt-2"
                            :checked="useUserStore?.workspaceBooksForm?.book_uuids?.includes(book.uuid)"
                            @update:checked="checkBook(book.uuid, $event)"
                            :label="book.name"
                            :fieldId="book.uuid"
                            :key="book"
                            v-model="useUserStore.workspaceBooksForm.book_uuids"/>
        <div class="flex justify-end">
          <button-component buttonText="Submit" buttonColor="secondary" prefix-icon="fi fi-rr-paper-plane-top" class="mt-4"
                            :is-loading="useUserStore.isLoadingUserBooks"/>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
import {userStore} from "@/views/users/stores/user.store.js";
import {libraryStore} from "@/views/library/stores/library.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import CheckboxComponent from "@/components/forms/CheckboxComponent.vue"


export default {
  name: 'EditUserBooksViewComponent',
  components: {ButtonComponent, CheckboxComponent},
  setup() {
    const useUserStore = userStore();
    const useLibraryStore = libraryStore();
    return {
      useUserStore,
      useLibraryStore,

    }
  },
  props: {
    workspaceUuid: {
      type: String,
      required: true
    },
    workspaceBooks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      books: [],
    }
  },
  mounted() {
    this.setUserBooks()
    this.getBooks()
  },
  methods: {
    async checkBook(bookUuid, checked) {
      let storeSelectedBooks = this.useUserStore.workspaceBooksForm.book_uuids
      if (checked) {
        storeSelectedBooks.push(bookUuid)
      } else {
        storeSelectedBooks.splice(storeSelectedBooks.indexOf(bookUuid), 1)
      }
    },
    async submitUserBooks() {
      this.useUserStore.isLoadingUserBooks = true;
      await this.useUserStore.createWorkspaceBooks();
    },

    async setUserBooks() {
      this.useUserStore.workspaceBooksForm.book_uuids = this.useUserStore.selectedWorkspaceBooks.map(book => book.uuid)
    },
    async getBooks() {
      await this.useLibraryStore.fetchAllBooks();
      this.books = this.useLibraryStore.books;
    },
    closeModal() {
      this.useUserStore.showEditUserBooksModal = false
    }
  }
}
</script>
