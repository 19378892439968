<template>
  <span :class="badgeClasses" :style="{ color: textColor, backgroundColor: badgeColor }">
    {{ badgeText }}
  </span>
</template>

<script>
export default {
  name: 'BadgeComponent',
  props: {
    badgeText: {
      type: String,
      required: true
    },
    badgeColor: {
      type: String,
      default: '#ffffff'
    },
    textColor: {
      type: String,
      default: '#000000'
    },
    size: {
      type: String,
      default: "md"
    },
    badgeType: {
      type: String,
      default: 'solid'
    },
  },
  computed: {
    badgeClasses() {
      return [
        this.badgeType === 'outline' ? 'border' : '',
        this.badgeType === 'outline' ? 'bg-transparent' : '',
        this.setSize.fontSize,
        this.setSize.padding,
        "font-semibold",
        "rounded-full",
        "inline-block",
        "mb-2",
        "w-auto"
      ];
    },
    setSize() {
      switch (this.size) {
        case 'sm':
          return {
            fontSize: 'text-xs',
            padding: 'px-1 py-0.5'
          }
        case 'md':
          return {
            fontSize: 'text-sm',
            padding: 'px-2 py-1'
          }
        default:
          return {
            fontSize: 'text-lg',
            padding: 'px-3 py-2'
          }
      }
    }
  }
}
</script>
