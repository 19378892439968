<template>
  <div class="border rounded-lg shadow-lg p-8 bg-white md:h-[calc(100vh-140px)]">
    <div class="flex flex-wrap justify-between items-center mb-4">
      <h1 class="text-2xl font-bold">{{ $t('overview lessons').capitalize() }}</h1>
      <button-component buttonText="create lesson" buttonColor="secondary" prefix-icon="fi fi-br-book-plus" v-if="useAuthStore.permissions?.includes('books_create')" @click="openCreateModal()" class="ml-auto bg-FLregistration text-white py-2 px-4 rounded-full">
        <span class="hidden md:inline">{{ $t('create lesson').capitalize() }}</span>
        <i class="fi fi-rr-layer-plus md:hidden"></i>
      </button-component>
    </div>

    <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4 p-4 overflow-y-auto md:max-h-[calc(100vh-300px)] max-h-[calc(100vh-300px)]" v-if="libraries.length > 0" @scroll="handleScroll">
      <card v-for="library in libraries" :key="library.uuid"
            :class="['h-full text-white rounded transform transition duration-200 hover:scale-105 hover:shadow cursor-pointer hover:bg-FLPrimary bg-FLSecondary']"
            @edit="updateBook(library.uuid)"
            @delete="confirmDeleteBook(library.uuid)"
            @show="openBook($event, library)"
            updatePermission="books_update"
            deletePermission="books_delete"
            showPermission="books_view"
            :allow-edits="true"
            :title="library.bookName"
            :is-locked="isLocked(library.is_demo)"
            :is-demo="!!library.is_demo"
            :updated-at="library.updated_at"
            :categories="library.categories"
            :image="library.image"
            :show-image="library.showImage"
            :views="library.views"
            title-size="lg"/>
    </div>
    <div v-else>
      <h1 class="text-xl">{{ $t('there are no lessons').capitalize() }}</h1>
    </div>
    <LoadingOverlay v-if="useLibraryStore.isLoadingBooks" />
  </div>
</template>

<script>
import {ref} from 'vue';
import Card from "@/components/CardComponent.vue";
import LoadingOverlay from "./LoadingOverlay.vue";
import { libraryStore } from "@/views/library/stores/library.store.js";
import { authStore } from "@/views/auth/stores/auth.store.js";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import { showDeleteConfirmation } from "@/helpers/sweetAlert";

export default {
  name: 'OverviewBookComponent',
  components: { ButtonComponent, Card, LoadingOverlay },

  setup() {
    const useLibraryStore = libraryStore();
    const useAuthStore = authStore();
    const sweetAlertBook = ref(null);

    const confirmDeleteBook = (uuid) => {
      showDeleteConfirmation(() => deleteBook(uuid));
    };

    const deleteBook = async (uuid) => {
      try {
        useLibraryStore.loading = true;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await useLibraryStore.deleteBook(uuid);
      } catch (error) {
        console.error('Error deleting book:', error);
      } finally {
        useLibraryStore.loading = false;
      }
    };

    const loadMoreBooks = async () => {
      if (useLibraryStore.pagination.pagesLoaded < useLibraryStore.pagination.totalPages) {
        useLibraryStore.pagination.currentPage += 1;
        await useLibraryStore.fetchAllBooks(useLibraryStore.pagination.currentPage, true);
      }
    };

    const handleScroll = (event) => {
      const bottomOfWindow = Math.round(event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight;

      if (bottomOfWindow && !useLibraryStore.isLoadingBooks) {
        loadMoreBooks();
      }
    };

    return {
      useLibraryStore,
      useAuthStore,
      sweetAlertBook,
      confirmDeleteBook,
      handleScroll,
    };
  },

  props: {
    libraries: {
      required: true,
      type: Array,
    },
  },

  methods: {
    isLocked(isDemo) {
      return this.useAuthStore?.user?.roles[0]?.name === 'demo_user' && !isDemo;
    },
    async openBook($e, book) {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.selectedBook = book;
        this.navigateTo($e, '/library/book/' + book.uuid);
      } catch (error) {
        console.error('Error opening book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    async openCreateModal() {
      try {
        this.useLibraryStore.loading = true;
        this.useLibraryStore.resetForm();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = false;
      } catch (error) {
        console.error('Error opening create modal:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },

    async updateBook(uuid) {
      try {
        this.useLibraryStore.loading = true;
        await this.useLibraryStore.fetchBook(uuid, false);
        await this.useLibraryStore.setFormBook();
        this.useLibraryStore.showEditBookModal = true;
        this.useLibraryStore.editMode = true;
      } catch (error) {
        console.error('Error updating book:', error);
      } finally {
        this.useLibraryStore.loading = false;
      }
    },
  },
};
</script>
