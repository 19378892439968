
<template>
  <i :class="icon + ' transform rotate-'+rotate"></i>
</template>

<script>

export default {
  name : 'IconComponent',

  props : {
        icon :{
          type: String
        },
        rotate: {
          type: Number,
          default: 0,
        }
  },
}

</script>