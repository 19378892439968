<template>
  <!-- Search bar -->
  <div class="relative">
    <input
        @input="handleInput"
        v-model="searchQuery"
        type="text"
        class="w-full h-10 pl-10 pr-10 text-sm text-gray-700 placeholder-gray-400 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
        :placeholder="$t('search for lessons').capitalize()"
    />
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none" v-if="!isLoading">
      <i class="fas fa-search text-gray-400"></i>
    </div>
    <div v-else class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#9CA3AF"></stop><stop offset=".3" stop-color="#9CA3AF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#9CA3AF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#9CA3AF" stop-opacity=".3"></stop><stop offset="1" stop-color="#9CA3AF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="0.7" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#9CA3AF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
    </div>
  </div>
</template>

<script>
import { libraryStore } from "@/views/library/stores/library.store";
import debounce from 'lodash/debounce';

export default {
  name: 'SearchComponent',

  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore,
    };
  },

  data() {
    return {
      searchQuery: '',
      isLoading: false,
    };
  },

  created() {
    this.debouncedCheckQuery = debounce(this.checkQuery, 1000);
  },

  methods: {
    handleInput() {
      this.isLoading = true;

      this.debouncedCheckQuery();

    },

    async checkQuery() {

      const searchFilter = this.useLibraryStore.filters.find(filter => filter.value === 'search');

      if (searchFilter) {
        searchFilter.filter[0].value = this.searchQuery;
      } else {
        this.useLibraryStore.filters.push({
          value: 'search',
          filter: [{
            value: this.searchQuery,
            checked: true,
          }],
        });
      }

      await this.useLibraryStore.setQueryFilters('search');
      this.isLoading = false;
    }
  },
};
</script>