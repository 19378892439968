<template>
  <div :class="['mx-auto mb-4 border-0 border-b-2 block', selectColors(inputColor).borderColor]">
    <div class="text-md" v-if="label">{{ $t(label.lowercase()).capitalize() }}</div>
    <select
        class="p-2 w-full"
        :value="modelValue"
        @change="$emit('update:modelValue', $event.target.value)"
    >
      <option disabled value="">{{$t(placeholder.lowercase()).capitalize() }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option.value">
        {{ $t(option.text.lowercase()).capitalize() }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: [String, Number]
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    selectColor: {
      type: String,
      default: 'default'
    },
    label: {
      type: String
    },
    inputColor: {
      type: String
    },
  },

  methods: {
    selectColors(selectColor) {
      if (selectColor === 'primary') {
        return { borderColor: 'border-b-FLPrimary' };
      } else if (selectColor === 'secondary') {
        return { borderColor: 'border-b-FLregistration' };
      } else {
        return { borderColor: 'border-b-gray-400' };
      }
    }
  }
}
</script>
