<template>
  <div>
    <div class="flex justify-between items-center md:py-4 pt-4 pr-4">
      <div class="md:hidden">
        <icon-component icon="fi fi-rr-menu-burger" class="p-8" @click.prevent="useAppStore.showSideNavigation = !useAppStore.showSideNavigation"/>
      </div>
      <div class="hidden md:block pr-3 text-base md:px-8 md:text-3xl">
        {{ $t(salutation()).capitalize() }}, <span class="font-bold">{{ useAuthStore.user.first_name }}</span>
      </div>
      <!-- User Info and Actions -->
      <div class="flex items-center">
        <!-- Bug Report Button -->
        <button @click="setBugReportModal" class="mr-2 rounded-lg text-lg md:pt-1.5">
          <i class="fi fi-sr-bug md:pt-1 md:mr-2 md:my-0.5"></i>
        </button>
        <!-- User Dropdown -->
        <div class="relative">
          <button class="md:p-2">
            <img :src="userAvatarUrl" alt="User Avatar" v-if="useAuthStore.user?.first_name && useAuthStore.user?.last_name">
          </button>
        </div>
      </div>
      <bug-report-component :modalIsOpen="useAppStore.showBugReportModal"/>
    </div>
    <!-- For Mobile Version -->
    <div class="pl-8 md:hidden text-base md:px-8 md:text-3xl">
      {{ $t(salutation()).capitalize() }}, <span class="font-bold">{{ useAuthStore.user.first_name }}</span>
    </div>
  </div>
</template>

<script>
import { authStore } from "@/views/auth/stores/auth.store";
import { appStore } from "@/views/app.store";
import IconComponent from "@/components/forms/IconComponent.vue";
import BugReportComponent from "@/components/forms/BugReportComponent.vue";
import { useRouter } from 'vue-router';


export default {
  name: 'TopNavigation',
  components: { IconComponent, BugReportComponent },
  data() {
    return {
      searchQuery: '',
      suggestions: [],
    };
  },
  computed: {
    userAvatarUrl() {
      return "https://ui-avatars.com/api/?size=35&name=" + this.useAuthStore.user.first_name + "+" + this.useAuthStore.user.last_name + "&rounded=true&background=51AFE3&color=fff";
    },

  },
  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();

    return {
      useAuthStore,
      useAppStore,
      router
    };
  },
  methods: {
    salutation() {
      let today = new Date();
      let curHr = today.getHours();
      if (curHr < 12) {
        return 'good morning';
      } else if (curHr < 18) {
        return 'good afternoon';
      } else {
        return 'good evening';
      }
    },
    setBugReportModal() {
      this.useAppStore.showBugReportModal = !this.useAppStore.showBugReportModal;
    },
    async openBook(book) {
      try {
        this.router.push(`/library/book/${book.uuid}`);
      } catch (error) {
        console.error('Error opening book:', error);
      }
    },
  }
};
</script>
