<template>
  <div v-if="useLibraryStore.openBookModal" id="popup-modal" tabindex="-1"
       class="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
    <div class="relative p-4 w-full max-w-4xl h-[calc(99vh)]">
      <!-- Overlay content -->
      <div class="relative bg-white rounded-lg shadow overflow-auto max-h-full">

        <!-- Close button -->
        <div class="sticky top-0 right-0 z-50">
          <button type="button"
                  class="absolute right-4 top-4 rounded-full bg-FLregistration text-white text-sm w-10 h-10 flex justify-center items-center"
                  @click="closeModal">
            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
            </svg>
          </button>
        </div>

        <!-- Modal content -->
        <div class="p-4 md:p-5 text-center">
          <vue-pdf-embed class="max-h-screen" :source="useLibraryStore.file"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed';
import {libraryStore} from "@/views/library/stores/library.store";

export default {
  components: {
    VuePdfEmbed,
  },
  setup() {
    const useLibraryStore = libraryStore();
    return {
      useLibraryStore
    }
  },
  methods: {
    closeModal() {
      this.useLibraryStore.openBookModal = false;
    },
  },
};
</script>