import ShowBook from "@/views/library/ShowBookView.vue";
import i18n from "@/i18n";

export default [
    {
        path: "/library/book/:uuid",
        component: ShowBook,
        meta: {
            title: (i18n.global.t("lesson information")),
            permission: 'books_view'
        },
    },
];
