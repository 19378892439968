
<template>
  <div class="bg-white flex flex-col sm:flex-row">
    <!-- Left side with the logo and login form -->
    <div class="md:w-1/2 sm:w-full flex flex-col items-center p-4 sm:p-8">
      <!-- Logo -->
      <img src="../../assets/logo_transparant.png" alt="Fluitend Leren Logo"
           class="mb-4 sm:mb-8 ml-4 sm:absolute sm:top-0 sm:left-0 sm:mt-4">

      <div class="items-center my-auto">
        <div class="text-center">
          <IconComponent icon="text-green-600 text-4xl fi fi-ss-shield-check"/>
        </div>
        <h1 class="text-2xltext-center sm:text-center">{{ $t('verify email').capitalize() }}
        </h1>
        <div class="mb-4 md:mb-8 text-center">
        <span class="underline"  @click="logout()">{{$t('back to login').capitalize()}}</span>
        </div>
        <div class="items-center">
          <form @submit.prevent="sendEmailConfirm()" class="text-center">
            <notification-popup v-if="notification.message" :message="notification.message" :type="notification.type"
                                @close="notification.message = ''"/>
            <p class="pb-5">
              {{ $t('your email has not been verified').capitalize() }}.
              {{ $t('please check your inbox to verify your email').capitalize() }}.
            </p>

            <p class="pb-2">
              {{ $t('if you did not receive an email, please click the button below').capitalize() }}:
            </p>
            <input-component v-model="useAuthStore.form.email" input-type="email" placeholder="email"
                             inputColor="primary" class="w-full" :required="true"/>
            <button-component type="submit" class="shadow-xl mx-auto w-full md:w-full " buttonText="send email verification mail"
                              :is-loading="useAuthStore.isLoading" buttonColor="secondary" :always-show-text="true"/>
          </form>
        </div>
      </div>
    </div>


    <div class="hidden sm:block sm:w-1/2">
      <img src="../../assets/RegistrationImage.png" alt="Girl Holding Book" class="w-full  sm:h-full object-cover">
    </div>
  </div>
</template>
<script>
import {authStore} from "@/views/auth/stores/auth.store";
import {appStore} from "@/views/app.store";
import {useRouter} from "vue-router";
import {ref} from "vue";
import InputComponent from "@/components/forms/InputComponent.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
import ButtonComponent from "@/components/forms/ButtonComponent.vue";
import IconComponent from "@/components/forms/IconComponent.vue";

export default {
  name: 'EmailVerifyWall',
  components: {InputComponent, IconComponent, ButtonComponent, NotificationPopup},

  setup() {
    const useAuthStore = authStore();
    const useAppStore = appStore();
    const router = useRouter();
    const notification = ref({
      message: '',
      type: 'error',
    });

    return {
      useAuthStore,
      useAppStore,
      router,
      notification,
    };
  },

  mounted() {
  },

  methods: {
    logout(){
      this.useAuthStore.logout();
    },
    sendEmailConfirm() {
      this.useAuthStore.sendEmailConfirm();

    }
  },
}
</script>