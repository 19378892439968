<template>
  <div class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" @click.self="$emit('close')">
    <div class="bg-white p-6 rounded-lg max-w-2xl w-full h-full overflow-y-auto relative">
      <h1 class="text-2xl font-bold mb-4">Privacybeleid</h1>
      <p class="mb-4"><strong>1. Inleiding</strong><br>
        Om gebruik te kunnen maken van de producten en diensten van Learn2Learn Publishing B.V., Hier handelend onder de naam Fluitend Leren, vraagt Fluitend Leren de gebruiker van de diensten om een aantal gegevens te verstrekken. Fluitend Leren gaat zorgvuldig om met deze gegevens en houdt zich in alle gevallen aan de Algemene Verordening Gegevensbescherming (AVG).
        Dit Privacy beleid is van toepassing op alle diensten van Fluitend Leren. Het doel van dit beleid is zorgen dat je begrijpt welke persoonlijke gegevens we van jou verzamelen en de reden waarom we deze gegevens verzamelen en gebruiken. Daarnaast willen we je uitleggen hoe wij jouw privacy beschermen en welke rechten en keuzemogelijkheden je hebt met betrekking tot de persoonlijke gegevens die wij van jou verzamelen en verwerken.
      </p>
      <p class="mb-4"><strong>2. Doeleinde gegevensverwerking</strong><br>
        Om gebruik te kunnen maken van de producten en diensten van Fluitend Leren wordt de gebruiker op de website gevraagd een tal van gegevens in te vullen of per e-mail aan Fluitend Leren te verzenden zoals naam, adres, woonplaats, en e-mailadres. Deze gegevens worden gebruikt voor de dienstverlening en om in het kader hiervan informatie te kunnen toesturen en te factureren.
      </p>
      <p class="mb-4"><strong>3. Gevenverstrekking aan derden</strong><br>
        Persoonlijke gegevens zullen te nimmer zonder voorafgaande uitdrukkelijke toestemming van de gebruiker worden verstrekt aan derden, behalve als dit noodzakelijk is voor de behoorlijke uitvoering van de overeenkomst die met Fluitend Leren is gesloten. Daarnaast is het mogelijk dat Fluitend Leren gegevens van de gebruiker op grond van een verplichting in verband met de geldende wet- en regelgeving aan een derde partij dient te verstrekken. De persoonsgegevens van de gebruiker worden dan uitsluitend verstrekt als duidelijk is dat deze wettelijke verplichting ook daadwerkelijk bestaat. Fluitend Leren behoudt het recht om de persoonsgegevens van de gebruiker aan de autoriteiten te verstrekken in geval van een vermoeden van fraude of misbruik van haar diensten. Fluitend Leren Behoudt het recht om versleutelde gegevens te delen met derden om zo andere (potentiele) klanten beter te kunnen bedienen. Waaronder begrepen maar niet beperkt tot: facebook en google.
      </p>
      <p class="mb-4"><strong>4. Beveiliging van gegevens</strong><br>
        Gegevens van de gebruiker die door Fluitend Leren worden verzameld en gebruikt, zijn alleen toegankelijk voor de medewerkers van Fluitend Leren, voor zover dat nodig is om hun functie uit te voeren. Fluitend Leren spant zich naar redelijkheid in om haar systemen goed te beveiligen en passende technische en organisatorische maatregelen te nemen voor wat betreft de te verrichten verwerkingen van persoonsgegevens, tegen verlies of tegen enige vorm van onrechtmatige verwerking (zoals onbevoegde kennisname, aantasting, wijziging of verstrekking van de persoonsgegevens).
      </p>
      <p class="mb-4"><strong>5. Inzage, correctie, verwijderen en contact</strong><br>
        De gebruiker kan op ieder moment en zonder kosten inzicht krijgen in zijn persoonsgegevens die door Fluitend Leren zijn verwerkt en deze gegevens naar wens aanpassen en/of laten verwijderen. Voor verdere vragen omtrent het Privacy beleid van Fluitend Leren, kan contact worden opgenomen met Fluitend Leren.
      </p>
      <p><strong>6. Wijzingen</strong><br>
        Fluitend Leren heeft het recht om wijzigingen aan te brengen in het Privacy beleid. Het wordt daarom aanbevolen om dit Privacy beleid geregeld te raadplegen, zodat de gebruiker van deze wijzingen op de hoogte is.
      </p>
      <button @click="$emit('close')" class="absolute top-2 right-2 text-gray-600">&times;</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>

